import React from 'react'
import PropTypes from 'prop-types'

import CongratsModal from '../components/CongratsModal'
import Layout from '../components/Layout'
import PitchForm from '../components/PitchForm'
import WelcomeModal from '../components/WelcomeModal'

const CreateScreen = ({ location }) => {

  return (
    <Layout>
      <PitchForm />

      {location.state?.signedUp && <WelcomeModal />}
      {location.state?.initiatedFirstStitch && <CongratsModal />}
    </Layout>
  )
}

CreateScreen.propTypes = {
  location: PropTypes.object
}

export default CreateScreen
