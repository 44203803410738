import css from './index.module.sass'

import React, { useEffect, useRef, useState, useCallback, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import Ionicon from '../Ionicon'
import List from '../List'
import Loader from '../Loader'
import Modal from '../Modal'
import Text from '../Text'
import VideoPlayer from '../VideoPlayer'

import TourTooltipContext from '../TourTooltipContext'

import Countdown from './Countdown'
import Teleprompt from './Teleprompt'
import TapeButton from './TapeButton'

import { TAPE2_CLASSNAME } from '../../constants/classnames'
import {
  EDIT_TELEPROMPT_TOOLTIP, PRACTICE_TAPE2_TOOLTIP, TAPE2_TOOLTIP, TRY_AGAIN_TOOLTIP
} from '../../constants/tooltips'
import ExamplePitchtapeLink from "../ExamplePitchVideo"
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"
import CameraError from "../VideosRecorder/CameraError";
import { isSafari } from 'react-device-detect'

const supportedBrowser = Boolean(window.MediaRecorder && navigator.mediaDevices)

const initialState = {
  ready: false,
  camera: supportedBrowser,
  countdown: false,
  recording: false,
  selectedTake: 0,
  takes: [],
  supportedBrowser: supportedBrowser,
  noCameraFound: false,
  lastTake: void 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'READY':
      return { ...state, ready: true }
    case 'COUNTDOWN_START':
      return { ...state, countdown: true }
    case 'COUNTDOWN_STOP':
      return { ...state, countdown: false }
    case 'RECORDING_START':
      return { ...state, countdown: false, recording: true }
    case 'RECORDING_STOP': {
      const nextTakes = [...state.takes]
      nextTakes[state.selectedTake] = action.blob

      return {
        ...state,
        camera: false,
        recording: false,
        ready: false,
        takes: nextTakes
      }
    }
    case 'SELECT_TAKE': {
      const nextCamera = !state.takes[action.index]

      return { ...state, selectedTake: action.index, camera: nextCamera, ready: state.camera && nextCamera }
    }
    case 'RETAKE': {
      const takes = state.takes.slice(0)
      takes[state.selectedTake] = null

      return { ...state, camera: true, takes }
    }
    case 'NO_CAMERA_FOUND':
      return { ...state, noCameraFound: true }
    default:
      return state
  }
}

const VideoRecorder = ({
  title, subject, teleprompt, telepromptExample, lastTake, saving, saveButtonText, practice,
  renderButtons, renderPromptModal, onBeforeSave, onSave
}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [nextLocation, setNextLocation] = useState()

  const { showTourTooltip, hideTourTooltip } = useContext(TourTooltipContext)

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    lastTake,
    ...(!practice && lastTake && {
      takes: [lastTake],
      selectedTake: 1
    })
  })

  const videoRef = useRef()
  const mediaRecorderRef = useRef()
  const timerIntervalRef = useRef()
  const retakeNumberRef = useRef(0)
  const closingStreamRef = useRef(0)
  const videoMetadata = useRef({})

  const saved = state.lastTake !== lastTake

  useEffect(() => {
    return () => {
      hideTourTooltip()
    }
  }, [hideTourTooltip])

  useEffect(() => {
    if (!state.camera) {
      return
    }

    const video = videoRef.current
    let stream

    closingStreamRef.current = false

    const promise = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: { min: 1280, max: 1280, },
        height: { min: 720, max: 720 },
      },
    })

    promise.then((_stream) => {
      if (closingStreamRef.current) {
        return _stream
      }

      stream = _stream
      const chunks = []

      video.srcObject = stream
      video.muted = true
      setCurrentTime(0)

      video.onloadedmetadata = () => {
        video.play()

        const { width, height } = stream.getVideoTracks()[0]?.getSettings() || {}

        videoMetadata.current = {
          ...videoMetadata.current,
          videoResolution: `${width}x${height}`,
        }
      }

      video.oncanplay = () => {
        dispatch({ type: 'READY' })
        showTourTooltip(EDIT_TELEPROMPT_TOOLTIP)
      }

      const mediaRecorder = new MediaRecorder(stream)

      mediaRecorder.onstart = () => {
        startTimer()

        dispatch({ type: 'RECORDING_START' })
        hideTourTooltip()
      }

      mediaRecorder.onstop = () => {
        clearInterval(timerIntervalRef.current)

        const blob = new Blob(chunks, {
          type: isSafari ? 'video/mp4' : '',
        })
        blob.name = 'video.webm'
        video.srcObject = null
        video.muted = false

        dispatch({ type: 'RECORDING_STOP', blob })
      }

      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      mediaRecorderRef.current = mediaRecorder
    })

    promise.catch((error) => {
      dispatch({ type: 'NO_CAMERA_FOUND' })
      console.error(error) // eslint-disable-line no-console
    })


    return () => {
      if (!stream) {
        closingStreamRef.current = true

        promise.then((stream) => {
          if (closingStreamRef.current) {
            for (const track of stream.getTracks()) {
              track.stop()
            }
          }
        })
      } else {
        const mediaRecorder = mediaRecorderRef.current

        video.onloadedmetadata = null
        video.oncanplay = null
        mediaRecorder.onstart = null
        mediaRecorder.onstop = null
        mediaRecorder.ondataavailable = null

        for (const track of stream.getTracks()) {
          track.stop()
        }
      }
    }
  }, [state.camera, showTourTooltip, hideTourTooltip])

  const startCountdown = () => {
    dispatch({ type: 'COUNTDOWN_START' })
  }

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setCurrentTime(time => time + 1)
    }, 1000)
  }

  const handleCountdownEnd = () => {
    const mediaRecorder = mediaRecorderRef.current
    mediaRecorder.start()
  }

  const handleRetake = useCallback(() => {
    if (practice) {
      retakeNumberRef.current++

      if (retakeNumberRef.current === 2) {
        showTourTooltip(TRY_AGAIN_TOOLTIP)
      }
    }

    dispatch({ type: 'RETAKE' })
  }, [showTourTooltip, practice])

  const handleStart = useCallback(() => {
    startCountdown()
  }, [])

  const handleStop = useCallback(() => {
    if (state.countdown) {
      dispatch({ type: 'COUNTDOWN_STOP' })
    } else {
      showTourTooltip(practice ? PRACTICE_TAPE2_TOOLTIP : TAPE2_TOOLTIP)
      mediaRecorderRef.current.stop()
    }
  }, [state.countdown, showTourTooltip, practice])

  const handleTakeA = useCallback(() => {
    if (state.recording || state.countdown || state.selectedTake === 0 || saving) {
      return
    }

    dispatch({ type: 'SELECT_TAKE', index: 0 })
  }, [state.recording, state.countdown, state.selectedTake, saving])

  const handleTakeB = useCallback(() => {
    if (state.recording || state.countdown || state.selectedTake === 1 || saving) {
      return
    }

    dispatch({ type: 'SELECT_TAKE', index: 1 })
  }, [state.recording, state.countdown, state.selectedTake, saving])

  const setLoadedVideoMetadata = (metadata) => {
    videoMetadata.current = {
      ...videoMetadata.current,
      ...metadata,
    }
  }

  const handleSave = useCallback(() => {
    const take = state.takes[state.selectedTake]

    setNextLocation(false)
    onSave(take, videoMetadata.current)
  }, [onSave, state.selectedTake, state.takes])

  const formatTime = (seconds) => {
    return `0:${String(seconds).padStart(2, '0')}`
  }

  const currentTake = state.takes[state.selectedTake]

  if (!state.supportedBrowser || state.noCameraFound) {
    return (
      <div className={css.browserNotSupported}>
        <div className={css.browserNotSupportedInner}>
          <Ionicon name='alert' size='48' color='red' />
          <Text tag='p' variant='standardLarger' offset='single-top'>
            {!state.supportedBrowser
              ? 'Your browser does not support video recording. Please use Chrome or Firefox to continue.'
              : <CameraError />
            }
          </Text>
        </div>
      </div>
    )
  }

  return (
    <article className={css.container}>
      <div className={css.videoContainer}>
        {state.camera
          ? (
            <>
              <video
                className={(state.camera && state.ready) ? css.videoRecording : css.video}
                ref={videoRef}
                loop
                controlsList='nodownload'
              />

              <div className={state.ready ? css.textWithOverlay : css.text}>
                {!state.recording &&
                  <Text tag='h1' variant='h3' weight='500'>{title}</Text>
                }
                <div className={(state.recording || state.countdown) ? css.textTipHidden : css.textTip}>
                  {SHOW_EXAMPLES &&
                    <Text tag="p" variant="standardLarger" weight="500">
                      <ExamplePitchtapeLink
                        linkText="Example&nbsp;Video"
                        variant="inherit"
                        section="video"
                      >
                      </ExamplePitchtapeLink>
                    </Text>
                  }
                </div>

                <Teleprompt
                  subject={subject}
                  defaultTeleprompt={teleprompt}
                  example={telepromptExample}
                  editable={!state.recording && !state.countdown}
                />
              </div>
            </>
          )
          : (
            <div className={css.player}>
              <VideoPlayer
                {...(currentTake.binary
                  ? {
                    binary: currentTake.binary
                  }
                  : {
                    binary: currentTake
                  }
                )}
                onLoadedVideoMetadata={setLoadedVideoMetadata}
              />
            </div>
          )
        }

        {state.countdown && <Countdown onEnd={handleCountdownEnd} />}

        {state.camera && !state.ready &&
          <div className={css.initializing}>
            <Loader variant='centered' icon={<Ionicon name='videocam' size='32' color='deepBlue' />} />
          </div>
        }

        {state.recording &&
          <div className={css.time}>
            <Text color='white'>
              Tape {state.selectedTake + 1}
              <i className={css.timeDot} />
              {formatTime(currentTime)}
            </Text>
          </div>
        }

        {(state.recording || state.countdown || state.camera) &&
          <div className={css.btnRecord}>
            {(state.recording || state.countdown)
              ? (
                <Button variant='recordStop' onClick={handleStop}>
                  <Text variant='standardLarger' weight='500' color='white'>Stop</Text>
                </Button>
              )
              : (
                <Button variant='record' onClick={handleStart} disabled={!state.ready}>
                  <Text variant='standardLarger' weight='500' color='white'>Start</Text>
                </Button>
              )
            }
          </div>
        }
      </div>

      <div className={css.footer}>
        <div className={css.takes}>
          <List variant='horizontal' gap='20'>
            <List.Item>
              <TapeButton
                active={state.selectedTake === 0}
                videoData={state.takes[0]}
                onClick={handleTakeA}
              >
                Tape 1
              </TapeButton>
            </List.Item>

            <List.Item>
              <TapeButton
                className={TAPE2_CLASSNAME}
                active={state.selectedTake === 1}
                videoData={state.takes[1]}
                onClick={handleTakeB}
              >
                Tape 2
              </TapeButton>
            </List.Item>
          </List>
        </div>

        {!state.camera &&
          <div className={css.actions}>
            {!saving &&
              <Button variant='outline' onClick={handleRetake}>
                Try again
              </Button>
            }

            {(!practice || saving) &&
              <Button
                variant='primary'
                offset='single-left'
                onClick={onBeforeSave || handleSave}
                disabled={saving}
              >
                <Text variant='button'>
                  {saving ? <>Saving<AnimatedEllipsis /></> : (saveButtonText || 'Save')}
                </Text>
              </Button>
            }
          </div>
        }

        {renderButtons && state.camera && !state.recording && (!state.takes.length || state.lastTake) &&
          <div className={css.actions}>
            {renderButtons()}
          </div>
        }
      </div>

      <Prompt
        message={(location) => {
          if (!nextLocation && !saved && (state.takes.length === 2 || state.takes[0] !== state.lastTake)) {
            setNextLocation(location)
            return false
          }

          return true
        }}
      />

      {nextLocation && (
        renderPromptModal
          ? renderPromptModal({ nextLocation, saving, onSave: handleSave })
          : (
            <Modal
              buttons={<>
                <Button variant='outline' to={nextLocation} disabled={saving}>Yes</Button>
                <Button variant='primary' onClick={() => setNextLocation(false)} disabled={saving}>
                  No
                </Button>
              </>}
            >
              <Text tag='p' variant='h3'>
                Are you sure you&apos;d like to exit the recording without saving?
              </Text>
            </Modal>
          )
      )}
    </article>
  )
}

VideoRecorder.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  teleprompt: PropTypes.string,
  telepromptExample: PropTypes.string,
  lastTake: PropTypes.object,
  saving: PropTypes.bool,
  saveButtonText: PropTypes.string,
  practice: PropTypes.bool,
  renderButtons: PropTypes.func,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func
}

export default VideoRecorder
