import css from './TemplateTopic.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import TemplateSegment from './TemplateSegment'

const TemplateTopic = ({ teleprompts, topic, defaultOpen }) => {
  return (
    <div className={css.container}>
      {topic.segments.map((segment, index) => {
        const telepromptData = teleprompts.find(p => p.subject === segment.id)
        const teleprompt = telepromptData && telepromptData.script

        return (
          <TemplateSegment
            key={segment.id}
            segment={segment}
            defaultTeleprompt={teleprompt}
            defaultOpen={defaultOpen && !index}
          />
        )
      })}
    </div>
  )
}

TemplateTopic.propTypes = {
  index: PropTypes.number,
  teleprompts: PropTypes.array,
  topic: PropTypes.object,
  defaultOpen: PropTypes.bool
}

export default TemplateTopic
