import css from './FeedScreen.module.sass'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import get from 'lodash.get'
import { useLocalStorage } from 'react-use'

import Layout from '../components/Layout'
import Alert from '../components/Alert'
import Button from '../components/Button'
import InvestorTour from '../components/InvestorTour'
import List from '../components/List'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import PitchtapeReview from '../components/PitchtapeReview'
import Text from '../components/Text'
import CurrentUserContext from '../components/CurrentUserContext'
import DevicesContext from '../components/DevicesContext'

import { formatGraphQLError } from '../helpers/errors'
import { usePitchtapeTracking } from '../helpers/hooks'
import { PROFILE_PATH } from '../constants/routes'
import { GET_FEED } from '../constants/queries'
import {
  FEED_NAME,
  PORTAL_NAME,
  VIDEO_CREATOR_PLURAL, VIDEO_REVIEWER_TOUR_ENABLED, VIDEO_REVIEWER_PROFILE_ENABLED
} from "../constants/enterpriseConfig"
import Ionicon from "../components/Ionicon"
import Link from "../components/Link"
import Search from "../components/Search"

const FeedScreen = ({ location, match }) => {
  const videoIdParam = match?.params?.videoId === ':videoId' ? 0 : match?.params?.videoId
  const [userFirstRegister, setUserFirstRegister] = useState(!!location?.state?.investorProfileCreated)
  const [canShowTour, setCanShowTour] = useState(false)
  const feedOffsetLS = Number(localStorage.getItem('feedOffset'))
  const [actionResult, setActionResult] = useState()
  const [offset, setOffset] = useState(videoIdParam ? videoIdParam - 1 : feedOffsetLS || 0)
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const [total, setTotal] = useState(0)
  const [openJumpToModal, setOpenJumpToModal] = useState(false)
  const [positionToJump, setPositionToJump] = useState(offset + 1)

  const { currentUser: { completedTourGuide } } = useContext(CurrentUserContext)
  const { mobile } = useContext(DevicesContext)

  const { data, loading, error, networkStatus, client } = useQuery(GET_FEED, {
    variables: {
      first: 1,
      offset,
      q: searchTerm,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getFeed?.total)
        setTotal(data.getFeed.total + offset)
      if (offset > 0 && !data.getFeed.edges?.length) {
        setOffset(0)
      }
    }
  })

  const edges = get(data, 'getFeed.edges')
  const hasPreviousPage = offset > 0
  const hasNextPage = get(data, 'getFeed.pageInfo.hasNextPage')
  const company = edges && edges.length && edges[0].node

  usePitchtapeTracking(company)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [company])

  const handleRate = useCallback((rating) => {
    client.writeQuery({
      query: GET_FEED,
      variables: { first: 1 },
      data: {
        getFeed: {
          ...data.getFeed,
          edges: [
            {
              ...data.getFeed.edges[0],
              node: {
                ...data.getFeed.edges[0].node,
                givenRating: rating
              }
            }
          ]
        }
      }
    })
  }, [data, client])

  useEffect(() => {
    localStorage.setItem('feedOffset', offset)
    setPositionToJump(offset + 1)
  }, [offset])

  const handleInvestorAction = useCallback((status) => {
    setActionResult({ company, status })
  }, [company])

  const handlePrevious = useCallback(() => {
    setOffset(o => o - 1)
  }, [])

  const handleNext = useCallback(() => {
    setOffset(o => o + 1)
  }, [])

  const searchCmp = (
    <div className={css.searchCmp}>
      <Search
          term={searchTerm}
          readOnly={true}
          editable
          onChange={setSearchTerm}
          onClear={removeSearchTerm}
      />
    </div>
  )

  const renderFilter = () => {
    return (
      <div className={`feedScreen ${css.filter}`}>
        <div className={css.totals}>
          {offset + 1} of {total} <Button variant='primarySmall' icon={
          <Ionicon name='iosShareAlt' color='white' size='16' style={{ marginLeft: '3px', marginRight: '-6px' }}>
          </Ionicon>} style={{ marginLeft: '0.5em' }} onClick={() => {
          setOpenJumpToModal(true)
        }}>Jump to</Button>
        </div>

        {searchTerm && searchTerm !== '' && searchCmp}
      </div>
    )
  }

  const renderEmptyFeed = () => {
    return <>
      <Text variant="h1" tag="h1" offset="half-bottom">
        The {PORTAL_NAME} is now live!
      </Text>

      <Text variant="large" tag="p" offset="double-bottom">
        {VIDEO_CREATOR_PLURAL} will now begin creating their {FEED_NAME.toLowerCase()} in the portal.
        Check back soon for updates!
      </Text>
      {VIDEO_REVIEWER_PROFILE_ENABLED &&
        <List variant="centeredActions">
          <List.Item style={{ maxWidth: 160 }}>
            <Button
              variant="iconOnTop"
              external
              href="https://pitchtape.com/blog"
              icon="blogBlue"
              iconMaxHeight={24}
            >
              Read Pitchtape&apos;s blog for tips on evaluating startups
            </Button>
          </List.Item>

          <List.Item style={{ maxWidth: 110 }}>
            <Button variant='iconOnTop' to={PROFILE_PATH} icon='pencilBlue' iconMaxHeight={24}>
              Update your investor profile
            </Button>
          </List.Item>
        </List>}
    </>
  }

  if (!company && !loading) {
    return (
      <Layout variant="dashboard">
        {renderFilter()}
        {renderEmptyFeed()}
      </Layout>
    )
  }

  const renderContent = () => {
    if (loading || networkStatus === 4) {
      return (
        <Layout noFooter variant='fullHeight'>
          <Loader variant='centered' />
        </Layout>
      )
    }

    if (error) {
      return (
        <Layout variant='dashboard'>
          <Alert variant='error'>{formatGraphQLError(error)}</Alert>
        </Layout>
      )
    }

    if (!company) {
      return (
        <Layout variant="dashboard">
          {renderFilter()}
          renderEmptyFeed()
        </Layout>
      )
    }

    return (
      <Layout background='white' noFooter>
        {renderFilter()}
        <PitchtapeReview
          key={company.id}
          shareable={true}
          company={company}
          forInvestor
          onRate={handleRate}
          hasPrevious={hasPreviousPage}
          hasNext={hasNextPage}
          onInvestorAction={handleInvestorAction}
          onPrevious={handlePrevious}
          onNext={handleNext}
          searchCmp={searchTerm && searchTerm !== '' ? searchCmp : null}
        />

        {(canShowTour || (!canShowTour && !userFirstRegister)) &&
          !completedTourGuide &&
          !mobile &&
          VIDEO_REVIEWER_TOUR_ENABLED &&
          <InvestorTour />
        }
      </Layout>
    )
  }
  return (
    <>
      {actionResult &&
        <Alert
          key={actionResult.company.id}
          variant="success"
          ttl={2000}
          noClose
          sticky
          centered
          onClose={() => setActionResult(null)}
        >
          You have{' '}
          {actionResult.status === 'saved'
            ? 'saved'
            : actionResult.status === 'connected'
              ? 'connected to'
              : 'passed on'
          }
          {' '}{actionResult.company.name}.
        </Alert>
      }

      {userFirstRegister &&
        <Modal
          variant="welcome"
          title={<Text tag="h1" variant="h2" color="white">Welcome!</Text>}
          buttons={
            <Button onClick={() => {
              setUserFirstRegister(false)
              setCanShowTour(true)
            }} variant="primary">
              View videos
            </Button>
          }
        >
          <div style={{ textAlign: 'center' }}>
            <Text tag="p" variant="larger" weight="400" offset="single-bottom">
              Thanks for creating your account on the Innovate UK Analysis for Innovators (A4I) Video Portal.
            </Text>
            <Text tag="p" variant="larger" weight="400" offset="single-bottom">
              You can now review and comment on the video submissions for the A4I competition.
            </Text>
            <Text tag="p" variant="larger" weight="400" offset="single-bottom">
              We are trialling this video platform as part of this competition. If you need
              assistance with using the video portal, you can contact the dedicated KTN A4I support team at
              <br />
              <Link href={"mailto:a4i@ktnuk.org"} external>
                a4i@ktnuk.org.
              </Link>
            </Text>
          </div>
        </Modal>
      }
      {openJumpToModal &&
        <Modal title={<Text variant='standardLarger' tag='h2'>You&apos;re currently viewing pitch #{offset + 1}</Text>}
          onClosed={() => setOpenJumpToModal(false)}
          content={<div className={css.jumpToContainer}>
            <div className={css.jumpToForm}>
              <Text variant='standardLarger' tag='p'>Jump to any pitch you&apos;d like to view</Text>
              <Text variant='standardLarger' tag='p'>Enter a number in the box below (1 - {total}):</Text>
              <input type="number" id="jumpTo" name="jumpTo" min="1" value={positionToJump} onChange={(event) => {
                if (event.target.value > 0 && event.target.value <= total) {
                  setPositionToJump(event.target.value)
                }

              }} max={total} />
            </div>
          </div>} buttons={
            <>
              <Button variant='outlineNarrow' onClick={() => setOpenJumpToModal(false)}>Cancel</Button>
              <Button variant='primaryNarrow' onClick={() => {
                setOffset(positionToJump - 1)
                setPositionToJump(offset + 1)
                setOpenJumpToModal(false)
              }
              }>Go!</Button>
            </>
          } />
      }
      {renderContent()}
    </>
  )
}

FeedScreen.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
}

export default FeedScreen
