import css from './Attachments.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Attachment from './Attachment'

const Attachments = ({ attachments, onRemove }) => {
  return (
    <div className={css.container}>
      {attachments.map(attachment =>
        <Attachment key={attachment.localId} attachment={attachment} onRemove={onRemove} />
      )}
    </div>
  )
}

Attachments.propTypes = {
  attachments: PropTypes.array,
  onRemove: PropTypes.func
}

export default Attachments
