import React from 'react'
import PropTypes from 'prop-types'

import Link from './Link'

const Pagination = ({ current, total, perPage, onChange }) => {
  const buttons = []
  const pagesAmount = Math.ceil(total / perPage)

  for (let i = 0; i < pagesAmount; i++) {
    buttons.push(
      <Link
        key={i}
        variant='pagination'
        active={current === i}
        onClick={() => onChange(i)}
      >
        {i + 1}
      </Link>
    )
  }

  return (
    <div>
      {buttons}
    </div>
  )
}

Pagination.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  perPage: PropTypes.number,
  onChange: PropTypes.func
}

export default Pagination
