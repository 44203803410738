import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import get from 'lodash.get'

import Alert from '../Alert'
import Chat from './Chat'

import { formatGraphQLError } from '../../helpers/errors'

const CONNECTION_CHAT = gql`
  mutation connectionChat ($connection: Int!) {
    connectionChat(connection: $connection) {
      channel
      authKey
    }
  }
`

const ChatLinker = (props) =>
  <Mutation mutation={CONNECTION_CHAT}>
    {(connectionChat, { data: chatData, loading: connecting, error: connectingError }) => {
      if (connectingError) {
        return <Alert variant='error'>{formatGraphQLError(connectingError)}</Alert>
      }

      return (
        <Chat
          {...props}
          channel={get(chatData, 'connectionChat.channel')}
          authKey={get(chatData, 'connectionChat.authKey')}
          connecting={connecting}
          onConnect={connectionChat}
        />
      )
    }}
  </Mutation>

ChatLinker.propTypes = {
  connectionId: PropTypes.string
}

export default ChatLinker
