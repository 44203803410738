import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import PitchtapeReview from '../components/PitchtapeReview'
import Alert from '../components/Alert'
import Loader from '../components/Loader'

import { formatGraphQLError } from '../helpers/errors'
import { areCompanyDetailsComplete } from '../helpers/companies'
import { CREATE_PATH } from '../constants/routes'
import { GET_USER_COMPANY, PUBLISH_COMPANY, UNPUBLISH_COMPANY } from '../constants/queries'
import { updateCompanyCachePublishDate } from "../components/PitchForm/cacheActions"
import { gtmTrack } from "../helpers/tracking"
import { GTM_PITCHTAPE_PUBLISHED_EVENT } from "../constants/gtm"
import SubmitToFeedModal from "../components/PitchForm/SubmitToFeedModal"
import Button from "../components/Button"
import SubmittedModal from "../components/PitchForm/SubmittedModal"
import RemoveFromFeedModal from "../components/PitchForm/RemoveFromFeedModal"
import RemovedFromFeedModal from "../components/PitchForm/RemovedFromFeedModal"

const HomeScreen = ({ location }) => {

  const [company, setCompanyResult] = useState()

  const { error, loading } = useQuery(GET_USER_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.company) {
        setCompanyResult(data.company)
      }
    }
  })

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openCongratsModal, setOpenCongratsModal] = useState(false)
  const [openRemoveFromFeedModal, setOpenRemoveFromFeedModal] = useState(false)
  const [openRemovedFromFeedModal, setOpenRemovedFromFeedModal] = useState(false)
  const [publishCompany, { loading: publishing }] = useMutation(PUBLISH_COMPANY, {
    update: (cache, { data: { publishCompany: { errors } } }) => {
      if (errors) {
        return
      }

      updateCompanyCachePublishDate(cache, (new Date()).toISOString())
    },
    onCompleted: () => {
      gtmTrack(GTM_PITCHTAPE_PUBLISHED_EVENT, {
        pitchtape: { id: company.id }
      })
      // Close the confirm Modal
      if (openConfirmModal)
        toggleConfirmModal()
      // Open the Congrats Modal
      if (!openCongratsModal)
        toggleCongratsModal()
    }
  })

  const [unpublishCompany, { loading: unpublishing }] = useMutation(UNPUBLISH_COMPANY, {
    update: (cache, { data: { unpublishCompany: { errors } } }) => {
      if (errors) {
        return
      }
      updateCompanyCachePublishDate(cache, null)
    },
    onCompleted: () => {
      // Close the confirm remove from feed Modal
      if (openRemoveFromFeedModal)
        toggleRemoveFromFeedModal()
      // Open the verify deleted Modal
      toggleRemovedFromFeedModal()
    }
  })


  const toggleRemoveFromFeedModal = (() => {
    setOpenRemoveFromFeedModal(!openRemoveFromFeedModal)
  })

  const toggleConfirmModal = (() => {
    setOpenConfirmModal(!openConfirmModal)
  })

  const toggleCongratsModal = (() => {
    if (openConfirmModal)
      toggleConfirmModal()
    setOpenCongratsModal(!openCongratsModal)
  })

  const toggleRemovedFromFeedModal = (() => {
    if (openRemoveFromFeedModal)
      toggleRemoveFromFeedModal()
    setOpenRemovedFromFeedModal(!openRemovedFromFeedModal)
  })
  const handleSubmitClick = useCallback(() => {
    if (!publishing) {
      publishCompany()
    }
  }, [publishCompany, publishing])

  const handleUnpublishClick = useCallback(() => {
    if (!unpublishing) {
      unpublishCompany()
    }
  }, [unpublishCompany, unpublishing])


  if (company) {
    if (!company.concatenatedVideo || !areCompanyDetailsComplete(company)) {
      return <Redirect to={{ pathname: CREATE_PATH, state: location.state }} />
    }
  }


  return (
    <>
      {openConfirmModal && <SubmitToFeedModal
        buttons={<>
          <Button variant='outline' onClick={toggleConfirmModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleSubmitClick}>Submit</Button>
        </>}
        visible={openConfirmModal}
        loading={publishing}
      />}
      {openCongratsModal
        && <SubmittedModal buttons={<Button variant='primary' onClick={toggleCongratsModal}>Done</Button>}
          visible={openCongratsModal}>
        </SubmittedModal>}

      {openRemoveFromFeedModal &&
        <RemoveFromFeedModal
          buttons={<>
            <Button variant='outline' onClick={toggleRemoveFromFeedModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleUnpublishClick}>Remove</Button>
          </>}
          visible={openRemoveFromFeedModal}
          loading={unpublishing} />}

      {openRemovedFromFeedModal &&
        <RemovedFromFeedModal
          buttons={<Button variant='primary' onClick={toggleRemovedFromFeedModal}>Done</Button>}
          visible={openRemovedFromFeedModal}
        />}
      {loading &&
        <Layout>
          <Loader offset='single' />
        </Layout>
      }
      {error &&
        <Layout variant='dashboard'>
          <Alert variant='error'>{formatGraphQLError(error)}</Alert>
        </Layout>
      }
      {company &&
        <Layout background={'white'} noFooter>
          <PitchtapeReview
            company={company}
            editable
            shareable
          />
        </Layout>}
    </>
  )
}

HomeScreen.propTypes = {
  location: PropTypes.object
}

export default HomeScreen
