import css from './CompanyDetails.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import {
  FormRow,
  FormCol,
  TextField
} from '../Form'
import SectionTitle from '../SectionTitle'

const CompanyDetails = ({
  name, description, onUpdate, onInvalid, highlights
}) => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('companyDetailsCollapsed') === 'true')

  const handleCollapseClick = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('companyDetailsCollapsed', !collapsed)
  }

  return (
    <section>
      <div className={collapsed ? css.btnCollapseActive : css.btnCollapse} onClick={handleCollapseClick}>
        <Button variant='icon'>
          <Ionicon name={collapsed ? 'arrowDropdown' : 'arrowDropup'} color='deepBlue' size='24' />
        </Button>
      </div>

      <SectionTitle
        required
        title='1. Basic Info'
      />

      {!collapsed &&
        <>
          <FormRow>
            <FormCol>
              <TextField
                label='Company Name *'
                sublabel='Enter the name of your company'
                name='name'
                {...name}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Application Number *'
                sublabel='Enter the Application Number from your online application on the Innovate UK Innovation Funding Service'
                name='description'
                {...description}
                //maxlength={75}
                //counter
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Application Title *'
                sublabel='Enter the Application Title from your online application on the Innovate UK Innovation Funding Service'
                name='highlights'
                required
                {...highlights}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>


        </>
      }
    </section>
  )
}

CompanyDetails.propTypes = {
  name: PropTypes.object,
  locations: PropTypes.object,
  highlights: PropTypes.object,
  description: PropTypes.object,
  customerCategory: PropTypes.object,
  industries: PropTypes.object,
  stage: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func
}

export default CompanyDetails
