import css from './PrivacyBlock.module.sass'
import React, { useCallback, useState, useImperativeHandle, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-apollo'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import Well from '../Well'
import SectionTitle from '../SectionTitle'


import { GTM_PITCHTAPE_PUBLISHED_EVENT } from '../../constants/gtm'
import { gtmTrack } from '../../helpers/tracking'
import { PRODUCT_NAME, PRODUCT_NAME_SHORT, SUBMIT_VIDEO_ENABLED } from "../../constants/enterpriseConfig"
import SubmitToFeedModal from "./SubmitToFeedModal"
import SubmittedModal from "./SubmittedModal"
import Icon from "../Icon"
import RemoveFromFeedModal from "./RemoveFromFeedModal"
import RemovedFromFeedModal from "./RemovedFromFeedModal"
import { VIDEO_FINISHED_STATUS } from "../../constants/videos"
import { HEADER_CLASSNAME } from '../../constants/classnames'
import { updateCompanyCachePublishDate } from "./cacheActions"
import { PUBLISH_COMPANY, UNPUBLISH_COMPANY } from "../../constants/queries"
import { getBounds } from '../../helpers/ui'

const SCROLL_OFFSET = 25

const PrivacyBlock = ({ company, blockRef }) => {
  const submitRef = useRef()
  const headerHeight = useMemo(() => {
    const header = document.querySelector(`.${HEADER_CLASSNAME}`)

    return header ? header.clientHeight : 0
  }, [])

  const [publishCompany, { loading: publishing, error: publishError }] = useMutation(PUBLISH_COMPANY, {
    update: (cache, { data: { publishCompany: { errors } } }) => {
      if (errors) {
        return
      }

      updateCompanyCachePublishDate(cache, (new Date()).toISOString())
    },
    onCompleted: () => {
      gtmTrack(GTM_PITCHTAPE_PUBLISHED_EVENT, {
        pitchtape: { id: company.id }
      })
      // Close the confirm Modal
      if (openConfirmModal)
        toggleConfirmModal()
      // Open the Congrats Modal
      if (!openCongratsModal)
        toggleCongratsModal()
    },
    onError: () => {
      console.log('There was an error publishing aborting operation.')
    }
  })

  const [unpublishCompany, { loading: unpublishing }] = useMutation(UNPUBLISH_COMPANY, {
    update: (cache, { data: { unpublishCompany: { errors } } }) => {
      if (errors) {
        return
      }

      updateCompanyCachePublishDate(cache, null)
    },
    onCompleted: () => {
      // Close the confirm remove from feed Modal
      if (openRemoveFromFeedModal)
        toggleRemoveFromFeedModal()
      // Open the verify deleted Modal
      toggleRemovedFromFeedModal()
    }
  })

  const scrollToSubmit = useCallback(() => {
    const { top } = getBounds(submitRef.current)

    window.scrollTo({ left: 0, top: top - headerHeight - SCROLL_OFFSET, behavior: 'smooth' })
  }, [headerHeight])

  useImperativeHandle(blockRef, () => ({
    scrollToSubmit
  }))

  const handleSubmitClick = useCallback(() => {
    if (!publishing) {
      publishCompany()
    }
  }, [publishCompany, publishing])

  const handleUnpublishClick = useCallback(() => {
    if (!unpublishing) {
      unpublishCompany()
    }
  }, [unpublishCompany, unpublishing])

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openCongratsModal, setOpenCongratsModal] = useState(false)
  const [openRemoveFromFeedModal, setOpenRemoveFromFeedModal] = useState(false)
  const [openRemovedFromFeedModal, setOpenRemovedFromFeedModal] = useState(false)

  const toggleRemoveFromFeedModal = (() => {
    setOpenRemoveFromFeedModal(!openRemoveFromFeedModal)
  })

  const toggleConfirmModal = (() => {
    setOpenConfirmModal(!openConfirmModal)
  })

  const toggleCongratsModal = (() => {
    if (openConfirmModal)
      toggleConfirmModal()
    setOpenCongratsModal(!openCongratsModal)
  })

  const toggleRemovedFromFeedModal = (() => {
    if (openRemoveFromFeedModal)
      toggleRemoveFromFeedModal()
    setOpenRemovedFromFeedModal(!openRemovedFromFeedModal)
  })

  const published = Boolean(company.publishedAt)
  const isDraft = Boolean(company.draft)
  const concatenatedVideoIsReady = company.concatenatedVideo?.status === VIDEO_FINISHED_STATUS

  return (
    <>

      {openConfirmModal && <SubmitToFeedModal
        buttons={<>
          <Button variant='outline' onClick={toggleConfirmModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleSubmitClick} disabled={publishError !== undefined}>Submit</Button>
        </>}
        visible={openConfirmModal}
        errors={publishError}
        loading={publishing}
      />}
      {openCongratsModal
        && <SubmittedModal buttons={<Button variant='primary' onClick={toggleCongratsModal}>Done</Button>}
          visible={openCongratsModal}>
        </SubmittedModal>}

      {openRemoveFromFeedModal &&
        <RemoveFromFeedModal
          buttons={<>
            <Button variant='outline' onClick={toggleRemoveFromFeedModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleUnpublishClick}>Remove</Button>
          </>}
          visible={openRemoveFromFeedModal}
          loading={unpublishing} />}

      {openRemovedFromFeedModal &&
        <RemovedFromFeedModal
          buttons={<Button variant='primary' onClick={toggleRemovedFromFeedModal}>Done</Button>}
          visible={openRemovedFromFeedModal}
        />}

      {
        SUBMIT_VIDEO_ENABLED &&
        <Well ref={submitRef}>
          <SectionTitle
            required
            title={`5. Submit ${PRODUCT_NAME}`}
            description={
              `Once all other steps are completed, click on the submit ${PRODUCT_NAME.toLocaleLowerCase()} 
          button to submit your ${PRODUCT_NAME.toLocaleLowerCase()} for review by the Analysis for Innovators (A4I) team.`
            }

          />
          <Button
            variant='primary'
            disabled={publishing || published || isDraft || !concatenatedVideoIsReady}
            onClick={toggleConfirmModal}>
            {published
              ? `${PRODUCT_NAME} Submitted`
              : publishing
                ? <>Submitting {PRODUCT_NAME}<AnimatedEllipsis /></>
                : `Submit ${PRODUCT_NAME}`
            }
          </Button>
          {published &&
            <Button variant='outline' className={css.RemovePitchFromFeed} onClick={toggleRemoveFromFeedModal}
              icon={<Icon name='xRed' opacity='50' style={{
                'borderRadius': '50%', 'border': '1.5px solid red', 'padding': '2px'
              }} />}>
              Remove {PRODUCT_NAME_SHORT.toLowerCase()}
            </Button>
          }
        </Well>
      }
    </>
  )
}

PrivacyBlock.propTypes = {
  company: PropTypes.object,
  blockRef: PropTypes.object
}

export default PrivacyBlock
