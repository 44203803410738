const EXTENSION_TO_ICON_NAME_MAP = {
  'pdf': 'files/pdf',
  'doc': 'files/doc',
  'docx': 'files/doc',
  'xls': 'files/xls',
  'xlsx': 'files/xls'
}

export function urlToIconName (url) {
  const [ extension ] = url.match(/\w+$/)

  return EXTENSION_TO_ICON_NAME_MAP[extension.toLowerCase()] || 'files/text'
}

export function urlToFileName (url) {
  const [ fileName ] = url.match(/[^/]+$/)
  return fileName
}

export function isImage (url) {
  return /(png|jpeg|jpg|gif)$/i.test(url)
}
