const getValue = (obj) => {
  return obj[process.env.REACT_APP_ENV] || obj.development
}

export const STRIPE_API_KEY = getValue({
  production: 'pk_live_YkNVV2unSxFIzsLS32uZeUKk00RB4pO4yF',
  development: 'pk_test_K7Rpa9Xafhp9L2xJlsETmAeK006Nz42oBC'
})

export const PUBNUB_PUBLISH_KEY = getValue({
  production: 'pub-c-e638d097-4fea-48f8-b413-c0f41a9be99e',
  staging: 'pub-c-e638d097-4fea-48f8-b413-c0f41a9be99e',
  development: 'pub-c-e638d097-4fea-48f8-b413-c0f41a9be99e'
})

export const PUBNUB_SUBSCRIBE_KEY = getValue({
  production: 'sub-c-87bc3ac6-c7ce-11eb-a9de-a6433017f026',
  staging: 'sub-c-87bc3ac6-c7ce-11eb-a9de-a6433017f026',
  development: 'sub-c-87bc3ac6-c7ce-11eb-a9de-a6433017f026'
})
