import React from 'react'

import CompanyList from '../components/CompanyList'
import Layout from '../components/Layout'

const CompanyListScreen = () => {
  return (
    <Layout variant='dashboard'>
      <CompanyList />
    </Layout>
  )
}

export default CompanyListScreen
