import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

import Button from '../../Button'
import Loader from '../../Loader'
import Alert from '../../Alert'
import List from '../../List'
import Text from '../../Text'
import Content from './Content'
import { Consumer as CurrentUserConsumer } from '../../CurrentUserContext'
import { Consumer as DevicesConsumer } from '../../DevicesContext'

import { isCompanyComplete } from '../../../helpers/companies'
import { formatGraphQLError } from '../../../helpers/errors'
import { CREATE_PATH, BILLING_PATH } from '../../../constants/routes'
import { GET_USER_COMPANY } from '../../../constants/queries'
import { START_PLAN_NAME, CANCELED_STATUS } from '../../../constants/plans'
import {PORTAL_NAME, PRODUCT_NAME, VIDEO_REVIEWER, VIDEO_REVIEWER_PLURAL} from "../../../constants/enterpriseConfig"

const ICON_PROPS = { name: 'tick', opacity: '50' }

const NoInvestorSelected = ({ hasConnections }) =>
  <DevicesConsumer>
    {({ mobile }) =>
      <CurrentUserConsumer>
        {({ currentUser: { subscription } }) =>
          <Query query={GET_USER_COMPANY} fetchPolicy='network-only'>
            {({ data, loading, error }) => {
              if (loading) {
                return <Loader variant='centered' />
              }

              if (error) {
                return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
              }

              const { company } = data

              if (hasConnections) {
                return (
                  <Content
                    heading='You have connections!'
                    text={`Select an ${VIDEO_REVIEWER.toLowerCase()}  on the left to start a chat.`}
                  />
                )
              } else if (!isCompanyComplete(company)) {
                return (
                  <Content
                    heading={`Complete your ${PRODUCT_NAME.toLocaleLowerCase()}.`}
                    text={<>
                      <Text tag='p'>
                        Please provide all required information to complete your {PRODUCT_NAME.toLocaleLowerCase()}.
                      </Text>
                    </>}
                    textMaxWidth={320}
                    status={`${PRODUCT_NAME.toLocaleLowerCase()} in progress`}
                    buttons={<Button variant='primary' to={CREATE_PATH}>Continue</Button>}
                  />
                )
              } else if (subscription.plan === START_PLAN_NAME || subscription.status === CANCELED_STATUS) {
                return (
                  <Content
                    heading={`Great job creating your ${PRODUCT_NAME.toLocaleLowerCase()}!`}
                    text={<>
                      <Text tag='p' offset='double-bottom'>
                        As a Start plan user you can create, update and share your {PRODUCT_NAME.toLocaleLowerCase()}.
                      </Text>

                      <div style={{ textAlign: 'left' }}>
                        <Text tag='p' offset='single-bottom' weight='500'>
                          To unlock additional benefits below upgrade to the Grow plan.
                        </Text>

                        <List variant='bulletedTicks' iconProps={ICON_PROPS}>
                          <List.Item>
                            Get a meeting with investors who want to connect with you
                          </List.Item>
                          <List.Item>
                            View, email and chat with your investor connections on our platform
                          </List.Item>
                          <List.Item>
                            Track investor engagement analytics and receive real-time notifications
                          </List.Item>
                          <List.Item>
                            Utilize private dashboard to manage activity and interactions with investors
                          </List.Item>
                          <List.Item>
                            Access aggregate feedback from investors on your pitch
                          </List.Item>
                          <List.Item>
                            Download your pitch videos for sharing, sending, or posting
                          </List.Item>
                        </List>
                      </div>
                    </>}
                    textMaxWidth='none'
                    status='pitchtape completed'
                    buttons={<Button variant='primary' to={BILLING_PATH}>Upgrade</Button>}
                  />
                )
              } else if (company.publishedAt && !company.draft) {
                return (
                  <Content
                    text={
                      mobile
                        ? `${VIDEO_REVIEWER_PLURAL} that connect with you will be listed here.`
                        : 'Your messages will appear here.'
                    }
                    status={`${PRODUCT_NAME.toLocaleLowerCase()} completed`}
                  />
                )
              } else {
                return (
                  <Content
                    heading={`Great job creating your ${PRODUCT_NAME.toLocaleLowerCase()}!`}
                    text={`You can now submit your ${PRODUCT_NAME.toLocaleLowerCase()} to our 
                    ${PORTAL_NAME.toLocaleLowerCase()} feed.`}
                    status={`${PRODUCT_NAME.toLocaleLowerCase()} completed`}
                  />
                )
              }
            }}
          </Query>
        }
      </CurrentUserConsumer>
    }
  </DevicesConsumer>



NoInvestorSelected.propTypes = {
  hasConnections: PropTypes.bool
}

export default NoInvestorSelected
