import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import Alert from '../components/Alert'
import Layout from '../components/Layout'
import VideosRecorder from '../components/VideosRecorder'

import { injectParams } from '../helpers/routes'
import { useUploadCompanyVideoSegment } from '../helpers/hooks'
import { formatGraphQLError } from '../helpers/errors'
import { TOPICS } from '../constants/videos'
import { CREATE_PATH, CREATE_VIDEOS_PATH } from '../constants/routes'

const CreateVideosScreen = ({ match, history }) => {
  const { topic, segment } = match.params

  const getPreviousSegmentUrl = () => {
    if (!topic || !segment) {
      return
    }

    const topicIndex = TOPICS.findIndex(t => t.id === topic)
    const segmentIndex = TOPICS[topicIndex].segments.findIndex(s => s.id === segment)

    let previousTopic = TOPICS[topicIndex]
    let previousSegment = previousTopic.segments[segmentIndex - 1]

    if (!previousSegment) {
      previousTopic = TOPICS[topicIndex - 1]
      previousSegment = previousTopic && previousTopic.segments[previousTopic.segments.length - 1]
    }

    if (previousTopic) {
      return injectParams(CREATE_VIDEOS_PATH, { topic: previousTopic.id, segment: previousSegment.id })
    }
  }

  const getNextSegmentUrl = () => {
    if (!topic || !segment) {
      return
    }

    const topicIndex = TOPICS.findIndex(t => t.id === topic)
    const segmentIndex = TOPICS[topicIndex].segments.findIndex(s => s.id === segment)

    let nextTopic = TOPICS[topicIndex]
    let nextSegment = nextTopic.segments[segmentIndex + 1]

    if (!nextSegment) {
      nextTopic = TOPICS[topicIndex + 1]
      nextSegment = nextTopic && nextTopic.segments[0]
    }

    if (nextTopic) {
      return injectParams(CREATE_VIDEOS_PATH, { topic: nextTopic.id, segment: nextSegment.id })
    }
  }

  const nextSegmentUrl = getNextSegmentUrl()

  useEffect(() => {
    if (!topic || !segment) {
      history.replace(injectParams(CREATE_VIDEOS_PATH, {
        topic: TOPICS[0].id,
        segment: TOPICS[0].segments[0].id
      }))
    }
  }, [topic, segment, history])

  const [
    uploadCompanyVideoSegment, { error: uploadingError, loading: uploading }
  ] = useUploadCompanyVideoSegment({
    onError: () => {
      console.log('There was an error uploading video segment aborting operation')
    }
  })

  const handleSave = useCallback((payload) => {
    return uploadCompanyVideoSegment({
      variables: {
        ...payload,
        metadata: JSON.stringify(payload.metadata || {}),
      },
    })
  }, [uploadCompanyVideoSegment])

  const handleStitch = useCallback((firstTime) => {
    history.push(CREATE_PATH, {
      initiatedStitch: true,
      initiatedFirstStitch: firstTime
    })
  }, [history])

  if (!topic || !segment) {
    return null
  }

  return (
    <Layout noHeader noFooter>
      {uploadingError &&
        <Alert variant='error'>{formatGraphQLError(uploadingError)}</Alert>
      }

      <VideosRecorder
        topics={TOPICS}
        topic={topic}
        segment={segment}
        previousSegmentUrl={getPreviousSegmentUrl()}
        nextSegmentUrl={nextSegmentUrl}
        exitUrl={CREATE_PATH}
        saving={uploading}
        onSave={handleSave}
        onStitch={handleStitch}
      />
    </Layout>
  )
}

CreateVideosScreen.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default CreateVideosScreen
