import css from './Attachment.module.sass'

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Loader from '../Loader'
import Icon from '../Icon'
import Text from '../Text'

import { urlToIconName, isImage } from '../../helpers/files'

const Attachment = ({ attachment, onRemove }) => {
  const handleRemoveClick = useCallback(() => {
    onRemove(attachment)
  }, [ attachment, onRemove ])

  const { file, url } = attachment

  return (
    <div className={css.container}>
      {isImage(file.name)
        ? <img className={css.image} src={URL.createObjectURL(file)} alt={file.name} />
        : <div className={css.notimage}>
          <Icon name={urlToIconName(file.name)} />

          <Text tag='p' variant='small' offset='quarter-top' truncated title={file.name}>{file.name}</Text>
        </div>
      }

      {!url && <Loader variant='blank' />}

      <div className={css.btnRemove}>
        <Button variant='icon' icon='xBlack' onClick={handleRemoveClick} />
      </div>
    </div>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.object,
  onRemove: PropTypes.func
}

export default Attachment
