import css from './index.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import SectionTitle from '../SectionTitle'
import Text from '../Text'

import TemplateTopic from './TemplateTopic'

import { TOPICS } from '../../constants/videos'

const TeleprompterScriptForm = ({ company }) => {
  const { teleprompts } = company

  return (
    <section>
      <SectionTitle
        title='2. Prepare Teleprompter Script'
      />

      <div className={css.intro}>
        <Text variant="standardLarger" weight="500" tag="h2">Instructions:</Text>
        <Text variant="standardLarger" tag="p" weight="300" offset="single-bottom">
          The text that you enter below will display on your screen as a teleprompter while you record your video.
          Please limit your entire video to <Text variant={null} weight="500">2 minutes total, </Text>
          which gives you about <Text variant={null} weight="500">40 seconds for each video topic. </Text>
          When writing your script, this corresponds to roughly
          <Text variant={null} weight="500"> 70-100 written words for each video topic.</Text>
        </Text>
      </div>
      <Text variant='blockTitle' offset="double-bottom" weight='500'>
        Video Topics:
      </Text>
      <br />
      <br />

      <div className={css.topics}>
        {TOPICS.map((topic, index) =>
          <TemplateTopic
            key={topic.id}
            index={index}
            topic={topic}
            teleprompts={teleprompts}
            defaultOpen={!index}
          />
        )}
      </div>
    </section>
  )
}

TeleprompterScriptForm.propTypes = {
  company: PropTypes.object
}

export default TeleprompterScriptForm
