import css from './ConnectionDetails.module.sass'

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Alert from '../Alert'
import Loader from '../Loader'
import Button from '../Button'
import Tooltip from '../Tooltip'
import Link from '../Link'
import { Consumer as DevicesConsumer } from '../DevicesContext'

import { formatDate } from '../../helpers/dates'
import { formatGraphQLError } from '../../helpers/errors'

const ConnectionDetails = ({ connection, loading, error, tall, onExpandToggle }) => {
  const [ expanded, setExpanded ] = useState(true)

  useEffect(() => {
    connection && connection.details && setExpanded(true)
  }, [ connection ])

  useEffect(() => {
    onExpandToggle()
  }, [ expanded, onExpandToggle ])

  const renderMultiple = (items) => {
    if (!items.length) {
      return 'All'
    }

    if (items.length > 3) {
      return (
        <>
          {items[0].name}
          {' '}
          <div className={css.other}>
            <Tooltip hoverable content={items.slice(1).map(i => i.name).join(', ')}>
              <Link variant='expanderInactive'>+ {items.length - 1} others</Link>
            </Tooltip>
          </div>
        </>
      )
    }

    return items.map(x => x.name).join(', ')
  }

  const renderDetail = (detail) => {
    return (
      <>
        <Text variant='smallUpper' tag='p' color='gray'>{detail.label}</Text>
        {Array.isArray(detail.value) ? renderMultiple(detail.value) : detail.value}
      </>
    )
  }

  return (
    <DevicesConsumer>
      {({ mobile }) =>
        <div className={tall ? css.containerTall : css.container}>
          {(loading || !connection)
            ? <Loader variant='centered' />
            : error
              ? <Alert variant='error'>{formatGraphQLError(error)}</Alert>
              : <>
                <div className={css.name}>
                  <Text tag='h1' variant='button'>
                    {connection.name}

                    {connection.details &&
                      <span className={css.expander}>
                        <Button
                          variant='icon'
                          icon={expanded ? 'arrowUpBlue' : 'arrowDownBlue'}
                          onClick={() => setExpanded(e => !e)}
                        />
                      </span>
                    }

                  </Text>
                  <Text tag='p' color='gray'>{connection.description}</Text>
                  {connection.contact &&
                    <Text tag='p' variant='small' offset='quarter-top'>
                      Contact name: {connection.contact}
                    </Text>
                  }
                </div>

                <div className={css.date}>
                  {!mobile
                    ? <Text variant='small' color='gray'>Date connected:</Text>
                    : <Text tag='p' variant='small' color='gray'>Date connected</Text>
                  }
                  {' '}
                  <Text variant='small'>{formatDate(connection.dateConnected)}</Text>
                </div>

                {connection.details && expanded &&
                  <div className={css.details}>
                    <ul className={css.ul}>
                      {connection.details.map((detail, index) =>
                        detail.value &&
                          <li className={css.li} key={index}>
                            {renderDetail(detail)}
                          </li>
                      )}
                    </ul>
                  </div>
                }
              </>
          }
        </div>
      }
    </DevicesConsumer>
  )
}

ConnectionDetails.propTypes = {
  connection: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  tall: PropTypes.bool,
  onExpandToggle: PropTypes.func
}

export default ConnectionDetails
