import css from './MenuDesktop.module.sass'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ConnectionsBadge from '../../ConnectionsBadge'
import Icon from '../../Icon'
import Ionicon from '../../Ionicon'
import Link from '../../Link'

import CurrentUserContext from '../../CurrentUserContext'

import {
  PITCHTAPE_PATH,
  CONNECTIONS_PATH,
  SETTINGS_PATH,
  SIGNOUT_PATH,
  ACTIVITY_PATH,
  PROFILE_PATH,
  CREATE_PATH,
  ANALYTICS_PATH,
  FEED_LIST_PATH,
  SAVED_PATH,
  FEED_GENERIC_PATH
} from '../../../constants/routes'
import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_ACTIVITY_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS, TOUR_INVESTOR_FEED_LIST_CLASS
} from '../../../constants/tour'
import {
  CONNECTIONS_MODULE_ENABLED,
  PRODUCT_NAME,
  VIDEO_REVIEWER_PROFILE_ENABLED,
  ANALYTICS_DASHBOARD_ENABLED, SAVED_MODULE_ENABLED
} from "../../../constants/enterpriseConfig"
import { matchPath, withRouter } from "react-router-dom"

const MenuDesktop = ({ location }) => {
  const { currentUser: { firstName, accountType } } = useContext(CurrentUserContext)
  const inAnalytics = !(matchPath(location.pathname, { path: ANALYTICS_PATH }) === null)
  const inConnections = !(matchPath(location.pathname, { path: ACTIVITY_PATH }) === null)
  const inSettings = !(matchPath(location.pathname, { path: SETTINGS_PATH }) === null)
  const inPitchProfile = !(matchPath(location.pathname, { path: CREATE_PATH }) === null)
  const inPitchView = !(matchPath(location.pathname, { path: PITCHTAPE_PATH }) === null)
  const inFeed = !(matchPath(location.pathname, { path: FEED_GENERIC_PATH, exact: false }) === null)
  const inFeedList = !(matchPath(location.pathname, { path: FEED_LIST_PATH }) === null)
  const inSavedList = !(matchPath(location.pathname, { path: SAVED_PATH }) === null)
  const inActivity = !(matchPath(location.pathname, { path: ACTIVITY_PATH }) === null)
  const inInvestorProfile = !(matchPath(location.pathname, { path: PROFILE_PATH }) === null)
  const inVideoProfileTab = inPitchView || inPitchProfile

  const renderInitialsDropdown = (accountPath, settingsPath) =>
    <li className={css.menuItemWithDropdown}>
      <Link variant='mainnav' nav to={VIDEO_REVIEWER_PROFILE_ENABLED ? accountPath : '#'}>
        <span className={css.initials}>{firstName.slice(0, 1)}</span>
        <Ionicon name='arrowDropdown' size='24' color='deepBlue' />
      </Link>
      <ul className={css.menuDropdownRight}>
        <li className={classNames(css.menuDropdownItem, { [css.SubNavActive]: inSettings })}>
          <Link
            variant='mainnavSub'
            active={inSettings}
            to={settingsPath}
            icon={<Ionicon name='settings' />}
          >
            Settings
          </Link>
        </li>

        {/* {accountType === 'founder' &&
          <li className={css.menuDropdownItem}>
            <Link
              variant='mainnavSub'
              to={BILLING_PATH}
              icon={<Ionicon name='card' />}
            >
              Plans & Billing
            </Link>
          </li>
        } */}

        <li className={css.menuDropdownItem}>
          <Link
            variant='mainnavSub'
            to={SIGNOUT_PATH}
            icon={<Ionicon name='power' />}
          >
            Log Out
          </Link>
        </li>
      </ul>
    </li>

  return (
    <>
      {accountType === 'founder' &&
        <ul className={css.menu}>
          <li className={css.menuItemWithDropdown}>
            <Link variant='mainnav' nav to={PITCHTAPE_PATH}
              activeClassName={inVideoProfileTab ? css.NavActive : ''}
              active={inVideoProfileTab}>
              {PRODUCT_NAME} Profile
              <Ionicon name='arrowDropdown' size='24' color='deepBlue' />
            </Link>

            <ul className={css.menuDropdown}>
              <li className={css.menuDropdownItem}>
                <Link variant="mainnavSub" to={CREATE_PATH} nav
                  activeClassName={inPitchProfile ? css.DropDownOptionActive : ''}
                  active={inPitchProfile}>Edit {PRODUCT_NAME} Profile</Link>
              </li>
              <li className={css.menuDropdownItem}>
                <Link variant="mainnavSub" to={PITCHTAPE_PATH} nav
                  activeClassName={inPitchView ? css.DropDownOptionActive : ''}
                  active={inPitchView} >View {PRODUCT_NAME} Profile</Link>
              </li>
            </ul>
          </li>
          {
            ANALYTICS_DASHBOARD_ENABLED &&
            <li className={css.menuItemAnalytics}>
              <Link variant="mainnav" nav to={ANALYTICS_PATH}
                activeClassName={inAnalytics ? css.NavActive : ''}
                active={inAnalytics} >
                Analytics Dashboard
              </Link>
            </li>
          }
          <li className={css.menuItemConnections}>
            {CONNECTIONS_MODULE_ENABLED &&
              <Link
                variant="mainnav"
                nav
                to={CONNECTIONS_PATH}
                activeClassName={inActivity ? css.NavActive : ''}
                active={inActivity}
                icon={<Icon name="envelope2" />}
              >
                Connections{' '}
                <span className={css.connectionsBadge}><ConnectionsBadge type={accountType} /></span>
              </Link>
            }
          </li>

          {renderInitialsDropdown(SETTINGS_PATH, SETTINGS_PATH)}
        </ul>
      }

      {accountType === 'investor' &&
        <ul className={css.menu}>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_FEED_CLASS}`}>
            <Link variant="mainnav" nav exact to={FEED_GENERIC_PATH} active={inFeed}
              activeClassName={css.NavActive}>Feed</Link>
          </li>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_FEED_LIST_CLASS}`}>
            <Link variant="mainnav" nav exact to={FEED_LIST_PATH}
              activeClassName={inFeedList ? css.NavActive : ''}
              active={inFeedList}>Feed List</Link>
          </li>
          {CONNECTIONS_MODULE_ENABLED &&
            <li className={`${css.menuItem} ${TOUR_INVESTOR_ACTIVITY_CLASS}`} >

              <Link variant="mainnav" nav to={ACTIVITY_PATH} active={inConnections}
                activeClassName={inConnections ? css.NavActive : ''}>
                Activity Dashboard{' '}
                <span className={css.connectionsBadge}><ConnectionsBadge type={accountType} /></span>
              </Link>

            </li>
          }
          {SAVED_MODULE_ENABLED &&
            <li className={css.menuItem}>

              <Link
                variant="mainnav"
                nav
                to={SAVED_PATH}
                active={inSavedList}
                activeClassName={inSavedList ? css.NavActive : ''}
              >
                Saved List{' '}
              </Link>

            </li>
          }
          <li className={`${css.menuItemProfile} ${TOUR_INVESTOR_PROFILE_CLASS}`}>
            {VIDEO_REVIEWER_PROFILE_ENABLED &&
              <Link variant="mainnav" nav to={PROFILE_PATH} activeClassName={inInvestorProfile ? css.NavActive : ''}
                active={inInvestorProfile}>My Profile</Link>
            }
          </li>


          {renderInitialsDropdown(PROFILE_PATH, SETTINGS_PATH)}
        </ul>
      }
    </>
  )
}

MenuDesktop.propTypes = {
  location: PropTypes.object
}

export default withRouter(MenuDesktop)
