export const getBounds = (_el) => {
  const el = typeof _el === 'string' ? document.querySelector(`.${_el}`) : _el

  const { top: _top, left: _left, width, height } = el.getBoundingClientRect()

  const top = _top + window.scrollY
  const left = _left + window.scrollX

  return {
    top,
    right: left + width,
    bottom: top + height,
    left,
    width,
    height
  }
}

export const getCss = (el, props) => {
  const computedStyle = window.getComputedStyle(el)

  if (Array.isArray(props)) {
    return props.map(p => {
      return computedStyle.getPropertyValue(p)
    })
  } else {
    return computedStyle.getPropertyValue(props)
  }
}
