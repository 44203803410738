import css from './Logo.module.sass'
import React from 'react'

import Button from './Button'
import Image from './Image'
import {LOGO, URL} from "../constants/enterpriseConfig"

const Logo = () =>
  <Button variant={null} external href={URL} block>
    <Image fileName={LOGO} alt='Pitchtape' block className={css.logo}/>
  </Button>

export default Logo
