import css from './RatingModal.module.sass'

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'

import Button from './Button'
import { Form, FormFooter, FormRow, FormCol, TextArea, RatingField } from './Form'
import Modal from './Modal'
import Text from './Text'
import Ionicon from './Ionicon'
import Loader from './Loader'
import Dropdown from './Form/Dropdown'

const GET_INVESTOR_COMPANY_RATING = gql`
  query getInvestorCompanyRating ($company: UUID) {
    getInvestorCompanyRating(company: $company) {
      answer1
      answer2
      rating
    }
  }
`

const GET_COMPANY_NAMES = gql`
  query getCompanyNames {
    companyNames: getCompanyNames {
      name
    }
  }
`

const RATE_COMPANY = gql`
  mutation rateCompany ($input: RateCompanyMutationInput!) {
    rateCompany(input: $input) {
      obj {
        id
        answer1
        answer2
        rating
        updatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

const RatingModal = ({ company, defaultOverall, onClose, onRate }) => {
  const defaultFormRows = '3'
  const [formData, setFormData] = useState({
    rating: null
  })

  const { data: ratingData, loading: fetching } = useQuery(GET_INVESTOR_COMPANY_RATING, {
    variables: { company: company.id },
    fetchPolicy: 'network-only'
  })

  const { data: companiesData } = useQuery(GET_COMPANY_NAMES, {
    variables: {},
  })

  const handleFieldUpdate = useCallback((patch) => {
    setFormData(x => ({ ...x, ...patch }))
  }, [])

  const options = useMemo(() => {
    return companiesData && companiesData.companyNames && companiesData.companyNames.map(el => ({ value: el.name, label: el.name }))
  }, [companiesData])

  useEffect(() => {

    if (ratingData) {
      handleFieldUpdate({
        rating: (ratingData.getInvestorCompanyRating?.rating === null ? defaultOverall :
          ratingData.getInvestorCompanyRating?.rating),
        ...(ratingData.getInvestorCompanyRating?.answer1 !== null && { answer1: ratingData.getInvestorCompanyRating?.answer1 }),
        ...(ratingData.getInvestorCompanyRating?.answer2 !== null && { answer2: ratingData.getInvestorCompanyRating?.answer2 }),
      })
    }
  }, [ratingData, defaultOverall, handleFieldUpdate])

  const [rateCompany, { loading: saving }] = useMutation(RATE_COMPANY, {
    variables: {
      input: {
        company: company.id,
        ...formData
      }
    },
    onCompleted: ({ rateCompany }) => {
      if (rateCompany.errors) {
        return console.error(rateCompany.errors)
      }

      onRate(rateCompany.obj.rating)
      onClose()
    },
  })

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    rateCompany()
  }, [rateCompany])

  const isLoading = useCallback(() => {
    if (fetching || saving || !options)
      return true
    return false
  }, [fetching, saving, options])

  return (
    <Modal
      variant='welcome'
      title={<>
        <Text tag="h1" variant="h2" color="white">
          Rate and review {company.name}
        </Text>

        <Button variant="icon" className={css.btnClose} onClick={onClose}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>}
      onOverlayClick={onClose}
      noButtons
    >
      {isLoading()
        ? <Loader />
        : (
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <FormCol>
                <Text tag="h3" offset="half-bottom">
                  What is your organisation&apos;s name?
                </Text>
                <Dropdown
                  options={options}
                  renderLabel={value => value.label}
                  name="answer1"
                  value={formData.answer1}
                  onUpdate={handleFieldUpdate}
                  required
                //    {...dropdownProps}
                />
              </FormCol>
            </FormRow>

            <FormRow offset='15'>
              <FormCol>
                <Text tag="h3">
                  Are you able to propose a solution to this problem?
                </Text>
                <RatingField
                  name="rating"
                  value={formData.rating}
                  required
                  onUpdate={handleFieldUpdate}
                  variant='large'
                  showLabels
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>

                <FormRow offset="15">
                  <FormCol>
                    <Text tag="h3">
                      Do you have any additional comments about this submission?
                    </Text>
                    <TextArea
                      name="answer2"
                      value={formData.answer2}
                      rows={defaultFormRows}
                      onUpdate={handleFieldUpdate}
                      required
                    />
                  </FormCol>
                </FormRow>

              </FormCol>
            </FormRow>

            <FormFooter offset='30'>
              <FormRow variant='inline-right' offset='20'>
                <FormCol variant='inline'>
                  <Button type='submit' variant='primary' disabled={saving}>Submit</Button>
                </FormCol>
                <FormCol variant='inline' offset='15'>
                  <Button type='base' variant='outline' onClick={() => {
                    onClose()
                  }}>Cancel</Button>
                </FormCol>
              </FormRow>
            </FormFooter>
          </Form>
        )
      }
    </Modal>
  )
}

RatingModal.propTypes = {
  company: PropTypes.object,
  defaultOverall: PropTypes.number,
  onClose: PropTypes.func,
  onRate: PropTypes.func
}

export default RatingModal
