import css from './Messages.module.sass'

import React, { useEffect, useState, useRef, useCallback, useReducer, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import PubNub from 'pubnub'

import Message from './Message'
import Loader from '../Loader'
import Text from '../Text'
import Alert from '../Alert'
import { Consumer as CurrentUserConsumer } from '../CurrentUserContext'

import { PUBNUB_SUBSCRIBE_KEY } from '../../constants/keys'
import { formatRelativeDateFromTimetoken, areSameDayTimetokens } from '../../helpers/dates'

const MESSAGES_PER_PAGE = 20

const initialState = {
  fetching: false,
  fetchingMore: false,
  messages: null,
  error: null,
  fetchedMore: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'RESET':
      return initialState

    case 'FETCH_MESSAGES_REQUEST':
      return { ...state, fetching: true, messages: null }
    case 'FETCH_MESSAGES_SUCCESS':
      return { ...state, fetching: false, messages: action.messages.concat(state.messages || []) }
    case 'FETCH_MESSAGES_FAILURE':
      return { ...state, fetching: false, error: action.error }

    case 'FETCH_MORE_MESSAGES_REQUEST':
      return { ...state, fetchingMore: true }
    case 'FETCH_MORE_MESSAGES_SUCCESS':
      return { ...state, fetchedMore: true, fetchingMore: false, messages: action.messages.concat(state.messages || []) }
    case 'FETCH_MORE_MESSAGES_FAILURE':
      return { ...state, fetchingMore: false }

    case 'ADD_MESSAGE':
      return { ...state, fetchedMore: false, messages: (state.messages || []).concat(action.message) }

    default:
      return state
  }
}

const Messages = React.forwardRef(({
  connecting, channel, authKey, emptyMessage, currentUserId
}, ref) => {
  const [ state, dispatch ] = useReducer(reducer, initialState)

  const [ messagesAvailable, setMessagesAvailable ] = useState()

  const pubnubRef = useRef()
  const startTimeTokenRef = useRef()
  const fetchingMoreRef = useRef()
  const prevScrollHeightRef = useRef()
  const containerRef = useRef()
  const scrolledToBottomRef = useRef()
  const lastContainerHeight = useRef()
  const forcedScrollRef = useRef()
  const forcedScrollTimeoutRef = useRef()

  const fetchMessages = useCallback(() => {
    let canceled

    let types = [ 'FETCH_MESSAGES_REQUEST', 'FETCH_MESSAGES_SUCCESS', 'FETCH_MESSAGES_FAILURE' ]

    if (startTimeTokenRef.current) {
      fetchingMoreRef.current = true
      types = [ 'FETCH_MORE_MESSAGES_REQUEST', 'FETCH_MORE_MESSAGES_SUCCESS', 'FETCH_MORE_MESSAGES_FAILURE' ]
    }

    dispatch({ type: types[0] })

    pubnubRef.current.history({
      channel,
      count: MESSAGES_PER_PAGE,
      start: startTimeTokenRef.current,
      stringifiedTimeToken: true
    }, (status, response) => {
      if (!canceled) {
        if (status.error) {
          console.log(status) // eslint-disable-line no-console
          return dispatch({ type: types[2], error: status.error })
        }

        fetchingMoreRef.current = false
        startTimeTokenRef.current =
          response.messages.length === MESSAGES_PER_PAGE ? response.startTimeToken : 0
        prevScrollHeightRef.current = containerRef.current.scrollHeight

        dispatch({ type: types[1], messages: response.messages })
      }
    })

    return () => {
      canceled = true
    }
  }, [ channel ])

  useEffect(function init () {
    if (!channel) {
      return
    }

    scrolledToBottomRef.current = true

    dispatch({ type: 'RESET' })

    const pubnub = new PubNub({
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      authKey,
      uuid: currentUserId
    })

    pubnubRef.current = pubnub

    const cancelFetchMessages = fetchMessages()

    const listener = {
      message: ({ timetoken, message }) => {
        dispatch({
          type: 'ADD_MESSAGE',
          message: {
            timetoken,
            entry: message
          }
        })
      }
    }

    pubnub.addListener(listener)

    pubnub.subscribe({
      channels: [ channel ],
      withPresence: true
    })

    return () => {
      cancelFetchMessages()

      pubnub.removeListener(listener)
      pubnub.unsubscribe({
        channels: [ channel ]
      })
    }
  }, [ channel, authKey, fetchMessages, currentUserId ])

  const isScrolledToBottom = () => {
    const containerEl = containerRef.current

    const totalScroll = containerEl.scrollHeight
    const currentScroll = containerEl.scrollTop + containerEl.clientHeight

    return totalScroll <= currentScroll
  }

  useEffect(function bindScrollHandler () {
    if (!channel) {
      return
    }

    let cancelFetchMessages
    const containerEl = containerRef.current

    const handleScroll = () => {
      if (forcedScrollRef.current) {
        return
      }

      const containerScrollTop = containerEl.scrollTop
      const containerHeight = containerEl.clientHeight

      if (
        !fetchingMoreRef.current &&
        startTimeTokenRef.current &&
        !prevScrollHeightRef.current &&
        (containerScrollTop <= containerHeight / 3)
      ) {
        cancelFetchMessages = fetchMessages()
      }

      scrolledToBottomRef.current = isScrolledToBottom()
      lastContainerHeight.current = containerHeight

      if (messagesAvailable && scrolledToBottomRef.current) {
        setMessagesAvailable(false)
      }
    }

    containerEl.addEventListener('scroll', handleScroll)

    return () => {
      cancelFetchMessages && cancelFetchMessages()

      containerEl.removeEventListener('scroll', handleScroll)
    }
  }, [ channel, fetchMessages, messagesAvailable ])

  const scrollTo = (position) => {
    clearTimeout(forcedScrollTimeoutRef.current)
    forcedScrollRef.current = true
    containerRef.current.scrollTop = position
    forcedScrollTimeoutRef.current = setTimeout(() => {
      forcedScrollRef.current = false
    }, 10)
  }

  const scrollToBottom = useCallback(() => {
    scrollTo(containerRef.current.scrollHeight)
  }, [])

  useEffect(function adjustScrollOnMessagesChange () {
    const containerEl = containerRef.current

    if (prevScrollHeightRef.current) {
      const scrollHeight = containerEl.scrollHeight

      scrollTo(scrollHeight - prevScrollHeightRef.current + containerEl.scrollTop)
      prevScrollHeightRef.current = null
    }
  }, [ state.messages ])

  useEffect(() => {
    if (state.fetchedMore) {
      return
    }

    const containerEl = containerRef.current

    const messages = state.messages
    const lastMessage = messages && messages[messages.length - 1]
    const isLastMessageMine = lastMessage && lastMessage.entry.from === currentUserId

    if (isLastMessageMine || scrolledToBottomRef.current) {
      scrollToBottom()
    } else if (containerEl.scrollHeight > containerEl.clientHeight + containerEl.scrollTop) {
      setMessagesAvailable(true)
    }
  }, [ state.messages, state.fetchedMore, currentUserId, scrollToBottom ])

  const handleImageLoad = useCallback(() => {
    if (scrolledToBottomRef.current) {
      scrollToBottom()
    }
  }, [ scrollToBottom ])

  useImperativeHandle(ref, () => ({
    requestScrollToBottom: () => {
      if (scrolledToBottomRef.current) {
        scrollToBottom()
      } else {
        const containerEl = containerRef.current
        const diff = containerEl.clientHeight - lastContainerHeight.current
        scrollTo(containerEl.scrollTop - diff)
      }
    }
  }))

  let prevDayTimetoken

  const renderContent = () => {
    const { fetching, fetchingMore, messages, error } = state

    if (!messages || fetching || connecting) {
      return <Loader variant='centered' />
    }

    if (error) {
      return <Alert variant={error}>{error}</Alert>
    }

    if (!messages.length) {
      return emptyMessage
    }

    return (
      <>
        {fetchingMore &&
          <div className={css.loader}>
            <Text variant='small' color='gray'>loading more messages...</Text>
          </div>
        }

        {messages.map(message =>
          <React.Fragment key={message.timetoken}>
            {(() => {
              if (!prevDayTimetoken || !areSameDayTimetokens(prevDayTimetoken, message.timetoken)) {
                prevDayTimetoken = message.timetoken

                return (
                  <div className={css.date}>
                    <Text variant='small' color='gray'>
                      {formatRelativeDateFromTimetoken(message.timetoken)}
                    </Text>
                  </div>
                )
              }
            })()}

            <Message
              timetoken={message.timetoken}
              message={message.entry.message}
              attachments={message.entry.attachments}
              mine={currentUserId === Number(message.entry.from)}
              onImageLoad={handleImageLoad}
            />
          </React.Fragment>
        )}
      </>
    )
  }

  return (
    <>
      <div className={css.container} ref={containerRef}>
        {channel && renderContent()}
      </div>

      {messagesAvailable &&
        <div className={css.messagesAvailable} onClick={() => scrollToBottom()}>
          <Text color='white'>new messages available</Text>
        </div>
      }
    </>
  )
})

Messages.displayName = 'Messages'

Messages.propTypes = {
  connecting: PropTypes.bool,
  channel: PropTypes.string,
  authKey: PropTypes.string,
  emptyMessage: PropTypes.node,
  currentUserId: PropTypes.number
}

const MessagesContainer = React.forwardRef((props, ref) =>
  <CurrentUserConsumer>
    {({ currentUser }) =>
      <Messages {...props} ref={ref} currentUserId={currentUser.id} />
    }
  </CurrentUserConsumer>
)

MessagesContainer.displayName = 'MessagesContainer'

export default MessagesContainer
