import css from './SharingBlock.module.sass'

import React, { useCallback, useImperativeHandle, useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
//import CompanyUrlCopier from '../CompanyUrlCopier'
import InfoTooltip from '../InfoTooltip'
import Well from '../Well'
import SectionTitle from '../SectionTitle'
import ShareVideoModal from './ShareVideoModal'

import { PRODUCT_NAME } from "../../constants/enterpriseConfig"
import { HEADER_CLASSNAME } from '../../constants/classnames'
import { getBounds } from '../../helpers/ui'
import { areCompanyDetailsComplete } from "../../helpers/companies"

const SCROLL_OFFSET = 25

const SharingBlock = ({ company, shareRef, updateCompany }) => {
  const [showModal, setShowModal] = useState(false)
  const isCompanyShareable = useCallback(()=>{
    if(areCompanyDetailsComplete(company) && company.concatenatedVideo
      && company.concatenatedVideo?.status === 'finished'){
      return true
    }else{
      return false
    }
  }, [company])
  const shareSectionRef = useRef()
  const headerHeight = useMemo(() => {
    const header = document.querySelector(`.${HEADER_CLASSNAME}`)

    return header ? header.clientHeight : 0
  }, [])

  const scrollToSubmit = useCallback(() => {
    const { top } = getBounds(shareSectionRef.current)

    window.scrollTo({ left: 0, top: top - headerHeight - SCROLL_OFFSET, behavior: 'smooth' })
  }, [headerHeight])

  useImperativeHandle(shareRef, () => ({
    scrollToSubmit
  }))

  const handleVideoShareModal = () => {
    setShowModal(true)
  }

  const handleShareVideoModalClose = () => {
    setShowModal(false)
  }

  const handleConfirmShare = useCallback(() => {
    updateCompany({
      variables: {
        input: {
          copyPasteUrl: true,
          validate: false
        }
      }
    })
    setShowModal(false)
  }, [updateCompany])

  return (
    <>
      <Well ref={shareSectionRef}>
        <SectionTitle
          title={`4. Copy & Paste ${PRODUCT_NAME} URL `}
          required
          nextToTitle={
            <InfoTooltip
              content={
                `To share your ${PRODUCT_NAME}, you must complete all required fields marked with an asterisk.`
              }
              style={{ marginTop: -15 }}
            />
          }
          description={`After completing your ${PRODUCT_NAME}, click on the copy ${PRODUCT_NAME}
        URL button to copy your ${PRODUCT_NAME} URL to your clipboard. Then, you must paste this
        ${PRODUCT_NAME} URL into your online application on the Innovate UK Innovation Funding Service.`}
        />

        <div className={css.buttons}>

          <Button
            disabled={ !(isCompanyShareable())}
            variant='primary'
            padding='double'
            onClick={handleVideoShareModal}
          >
            Copy {PRODUCT_NAME} URL
          </Button>

        </div>
      </Well>

      {
        showModal &&
        <ShareVideoModal
          //  company={company}
          // defaultOverall={temporaryRating}
          // onRate={onRate}
          onClose={handleShareVideoModalClose}
          onConfirm={handleConfirmShare}
        />
      }
    </>
  )
}

SharingBlock.propTypes = {
  company: PropTypes.object,
  shareRef: PropTypes.object,
  updateCompany: PropTypes.func
}

export default SharingBlock
