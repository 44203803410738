import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import InvestorConnections from '../components/InvestorConnections'
import { Consumer as DevicesConsumer } from '../components/DevicesContext'

import { ACTIVITY_PATH, HOME_PATH } from '../constants/routes'
import { toWithParams } from '../helpers/routes'
import { CONNECTIONS_MODULE_ENABLED } from "../constants/enterpriseConfig"

const ActivityScreen = ({ match }) => {
  const type = match.params.type

  if ( !CONNECTIONS_MODULE_ENABLED )
    return <Redirect to={ HOME_PATH }/>

  if ( !type ) {
    return <Redirect to={ toWithParams( ACTIVITY_PATH, { type: 'connected' } ) }/>
  }

  const selectedConnectionId = match.params.connectionId

  return (
    <DevicesConsumer>
      { ( { mobile } ) =>
        <Layout
          noHeader={ mobile && Boolean( selectedConnectionId ) }
          variant="fullHeight"
          noFooter
          background="white"
        >
          <InvestorConnections
            selectedConnectionId={ selectedConnectionId }
            type={ type }
          />
        </Layout>
      }
    </DevicesConsumer>
  )
}

ActivityScreen.propTypes = {
  match: PropTypes.object
}

export default ActivityScreen
