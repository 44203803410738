import css from './CompanyList.module.sass'

import React, { useState } from 'react'
import { useQuery } from 'react-apollo'

import Alert from './Alert'
import Loader from './Loader'
import Link from './Link'
import Pagination from './Pagination'

import { GET_COMPANIES } from '../constants/queries'
import { ANALYTICS_PATH } from '../constants/routes'
import { formatGraphQLError } from '../helpers/errors'

const ITEMS_PER_PAGE = 10

const CompanyList = () => {
  const [ pageIndex, setPageIndex ] = useState(0)

  const { data, loading, error } = useQuery(GET_COMPANIES, {
    variables: {
      first: 10,
      offset: pageIndex * ITEMS_PER_PAGE
    },
    fetchPolicy: 'network-only'
  })

  return (
    <div className={css.container}>
      {data && Boolean(data.getCompanies.total) &&
        <div className={css.pagination}>
          <Pagination
            current={pageIndex}
            total={data.getCompanies.total}
            perPage={ITEMS_PER_PAGE}
            onChange={index => setPageIndex(index)}
          />
        </div>
      }

      {error && <Alert variant='error'>{formatGraphQLError(error)}</Alert>}

      {loading
        ? <Loader />
        : (
          data.getCompanies.edges.map(({ node: company }) =>
            <div className={css.company} key={company.id}>
              <Link to={ANALYTICS_PATH} params={{ companyId: company.id }}>
                {company.name}
              </Link>
            </div>
          )
        )
      }
    </div>
  )
}

export default CompanyList
