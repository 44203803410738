import css from './EmptyChat.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

const EmptyChat = ({ message }) => {
  return (
    <div className={css.container}>
      <Text tag='p' variant='h3' offset='half-bottom'>Congrats!</Text>

      {message}
    </div>
  )
}

EmptyChat.propTypes = {
  message: PropTypes.node
}

export default EmptyChat
