import React from 'react'
import PropTypes from 'prop-types'
import {useQuery} from 'react-apollo'

import Copier from './Copier'

import { gtmTrack } from '../helpers/tracking'
import { toWithParams } from '../helpers/routes'
import { isCompanyShareable } from '../helpers/companies'
import { STARTUP_PATH } from '../constants/routes'
import { GET_USER_COMPANY } from '../constants/queries'
import { GTM_PITCHTAPE_URL_COPIED } from '../constants/gtm'

const CompanyUrlCopier = ({ children, company, urlSetState }) =>{
  const {loading, error, data} = useQuery(GET_USER_COMPANY,{
    skip: company
  })

  if(loading)
    return null
  if(error)
    return `Error! ${error}`

  if(!company){
    const disabled = !data || !data.company || !isCompanyShareable(data.company)
    return (
        <Copier
            value={disabled
                ? ''
                : (window.location.origin + toWithParams(STARTUP_PATH, { companyId: data.company.id }))
            }
            onCopy={() => {
              if(urlSetState && !disabled){
                urlSetState(window.location.origin + toWithParams(STARTUP_PATH, { companyId: data.company.id }))
              }
              gtmTrack(GTM_PITCHTAPE_URL_COPIED, {
                pitchtape: { id: data.company.id }
              })
            }}
        >
          {React.cloneElement(children, { disabled })}
        </Copier>)
  }else{

    return (
        <Copier
            value={(window.location.origin + toWithParams(STARTUP_PATH, { companyId: company.id }))
            }
            onCopy={() => {
              if(urlSetState){
                urlSetState(window.location.origin + toWithParams(STARTUP_PATH, { companyId: company.id }))
              }
              gtmTrack(GTM_PITCHTAPE_URL_COPIED, {
                pitchtape: { id: company.id }
              })
            }}
        >
          {React.cloneElement(children, {})}
        </Copier>
    )
  }
}

CompanyUrlCopier.propTypes = {
  children: PropTypes.node,
  company: PropTypes.object,
  urlSetState: PropTypes.func
}

export default CompanyUrlCopier
