import css from './ShareVideoModal.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { FormFooter, FormRow, FormCol } from '../Form'
import Modal from '../Modal'
import Text from '../Text'
import Ionicon from '../Ionicon'
import CompanyUrlCopier from '../CompanyUrlCopier'

const ShareVideoModal = ({ onClose, onConfirm }) => {
  const [companyUrl, setCompanyUrl] = useState(null)

  return (
    <Modal
      variant='copy'
      title={<>
        <Text tag="h1" variant="h2" color="white">
          Copy & Paste Confirmation
        </Text>

        <Button variant="icon" className={css.btnClose} onClick={onClose}>
          <Ionicon name="close" size="32" color="white" />
        </Button>
      </>}
      onOverlayClick={onClose}
      noButtons
    >
      {
        <>
          <div className={css.container}>
            <Text variant="standardLarger" color="black" offset="double-bottom" weight="500">
              Important: After completing your video, please complete the following 3 steps:
            </Text>

            <Text variant="standard" color="black" offset="double-bottom">
              1. Copy your video URL to your clipboard by clicking the copy video URL button below.
              <CompanyUrlCopier urlSetState={setCompanyUrl}>
                <Button variant='primary' offset='single-top' padding='double'>
                  Copy video URL
                </Button>
              </CompanyUrlCopier>

              {companyUrl &&
                <blockquote>
                  {companyUrl}
                </blockquote>
              }
            </Text>


            <Text variant="standard" color="black" offset="double-bottom">
              2. Paste your video URL into your online application on the Innovate UK Innovation Funding Service.
            </Text>

            <Text variant="standard" color="black" offset="double-bottom">
              3. After pasting your video URL into your online application on the Innovate UK Innovation Funding Service, click on the confirmed button below.
            </Text>

            <Text variant="standard" color="black" className={css.align}>
              If you need assistance, click cancel and contact Customer Support Service on 0300 321 4357 or support@iuk.ukri.org. You can also contact the dedicated KTN A4I support team at a4i@ktnuk.org.
            </Text>
            <FormFooter offset='30'>
              <FormRow variant='inline-right' offset='20'>
                <FormCol variant='inline' >
                  <Button type='base' variant='outline' onClick={() => {
                    onClose()
                  }}>Cancel</Button>
                </FormCol>
                <FormCol variant='inline' offset='15'>
                  <Button variant='primary' disabled={companyUrl === null} onClick={() => {
                    if(onConfirm){
                      onConfirm()
                    }
                    if(onClose){
                      onClose()
                    }

                  }}>Confirmed</Button>
                </FormCol>
              </FormRow>
            </FormFooter>
          </div>
        </>
      }
    </Modal>
  )
}

ShareVideoModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default ShareVideoModal
