import css from './SignUpScreen.module.sass'

import React, { useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../components/Alert'
import Footer from '../components/Footer'
import { FormErrors } from '../components/Form'
import Layout from '../components/Layout'
import Link from '../components/Link'
import Text from '../components/Text'
import SignUpForm from '../components/SignUpForm'

import CurrentUserContext from '../components/CurrentUserContext'

import { FEED_PATH, PITCHTAPE_PATH, SIGNIN_PATH } from '../constants/routes'
import { ERRORS_FOR_FIELDS_FRAGMENT } from '../constants/fragments'
import { GTM_USER_REGISTERED_EVENT } from '../constants/gtm'
import { gtmTrack, gtmIdentify } from '../helpers/tracking'
import { formatGraphQLError } from '../helpers/errors'
import { VIDEO_CREATOR_PLURAL, VIDEO_REVIEWER_PLURAL } from "../constants/enterpriseConfig"

const CREATE_USER = gql`
  mutation createUser (
    $email: String!,
    $firstName: String!,
    $lastName: String!,
    $accountType: String!,
    $password: String!
  ) {
    createUser (
      input: {
        email: $email,
        firstName: $firstName,
        lastName: $lastName,
        accountType: $accountType,
        password: $password
      }
    ) {
      obj {
        id
        firstName
        lastName
        email
        accountType
      }
      token
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

const SignUpScreen = ({ history, match }) => {
  const { onTokenChange } = useContext(CurrentUserContext)

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)

  const isInvestor = match.params.accountType === 'investor'

  const handleUserCreate = useCallback((variables) => {
    createUser({ variables })
      .then(({ data }) => {
        if (data && data.createUser.token) {
          gtmIdentify(data.createUser.obj.id)

          gtmTrack(GTM_USER_REGISTERED_EVENT, {
            user: { accountType: data.createUser.obj.accountType }
          })

          onTokenChange(data.createUser.token)

          if (isInvestor) {
            history.push(FEED_PATH, { investorProfileCreated: true })
          } else {
            history.push(PITCHTAPE_PATH, { signedUp: true })
          }
        }
      })
  }, [createUser, history, onTokenChange, isInvestor])

  const formErrors = data && data.createUser.errors

  return (
    <Layout variant='ukri' noFooter>
      <div className={css.container}>
        <div className={css.main}>
          <div className={css.mainInner}>
            <div className={css.form}>
              <div className={css.intro}>
                <Text tag="h2" variant="h3" offset="half-bottom">
                  {isInvestor ? VIDEO_REVIEWER_PLURAL : VIDEO_CREATOR_PLURAL} sign up here
                </Text>
                <Text tag="p">
                  Already have an account? <Link to={SIGNIN_PATH}>Log In</Link>
                </Text>
              </div>

              {formErrors && <FormErrors errors={formErrors} />}
              {error && <Alert variant="error">{formatGraphQLError(error)}</Alert>}

              <SignUpForm
                accountType={isInvestor ? 'investor' : 'founder'}
                loading={loading}
                onUserCreate={handleUserCreate}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

SignUpScreen.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default SignUpScreen
