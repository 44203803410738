import css from './Ionicon.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { PRIMARY_COLOR } from '../constants/colors'

import { ReactComponent as MdAdd } from '../images/ionicons/md-add.svg'
import { ReactComponent as MdRemove } from '../images/ionicons/md-remove.svg'
import { ReactComponent as MdAddCircleOutline } from '../images/ionicons/md-add-circle-outline.svg'
import { ReactComponent as MdRemoveCircleOutline } from '../images/ionicons/md-remove-circle-outline.svg'
import { ReactComponent as MdArrowBack } from '../images/ionicons/md-arrow-back.svg'
import { ReactComponent as MdArrowForward } from '../images/ionicons/md-arrow-forward.svg'
import { ReactComponent as MdBusiness } from '../images/ionicons/md-business.svg'
import { ReactComponent as MdPin } from '../images/ionicons/md-pin.svg'
import { ReactComponent as MdTrendingUp } from '../images/ionicons/md-trending-up.svg'
import { ReactComponent as MdBriefcase } from '../images/ionicons/md-briefcase.svg'
import { ReactComponent as MdBookmark } from '../images/ionicons/md-bookmark.svg'
import { ReactComponent as MdClose } from '../images/ionicons/md-close.svg'
import { ReactComponent as MdCheckmark } from '../images/ionicons/md-checkmark.svg'
import { ReactComponent as MdRadioButtonOff } from '../images/ionicons/md-radio-button-off.svg'
import { ReactComponent as MdRadioButtonOn } from '../images/ionicons/md-radio-button-on.svg'
import { ReactComponent as MdShare } from '../images/ionicons/md-share.svg'
import { ReactComponent as MdPlay } from '../images/ionicons/md-play.svg'
import { ReactComponent as MdPlayCircle } from '../images/ionicons/md-play-circle.svg'
import { ReactComponent as MdPause } from '../images/ionicons/md-pause.svg'
import { ReactComponent as MdVolumeHigh } from '../images/ionicons/md-volume-high.svg'
import { ReactComponent as MdVolumeOff } from '../images/ionicons/md-volume-off.svg'
import { ReactComponent as MdExpand } from '../images/ionicons/md-expand.svg'
import { ReactComponent as MdSkipBackward } from '../images/ionicons/md-skip-backward.svg'
import { ReactComponent as MdSkipForward } from '../images/ionicons/md-skip-forward.svg'
import { ReactComponent as MdPeople } from '../images/ionicons/md-people.svg'
import { ReactComponent as MdVideocam } from '../images/ionicons/md-videocam.svg'
import { ReactComponent as MdDocument } from '../images/ionicons/md-document.svg'
import { ReactComponent as MdCreate } from '../images/ionicons/md-create.svg'
import { ReactComponent as MdBold } from '../images/ionicons/md-bold.svg'
import { ReactComponent as MdItalic } from '../images/ionicons/md-italic.svg'
import { ReactComponent as MdUnorderedList } from '../images/ionicons/md-unordered-list.svg'
import { ReactComponent as MdOrderedList } from '../images/ionicons/md-ordered-list.svg'
import { ReactComponent as MdTitle } from '../images/ionicons/md-title.svg'
import { ReactComponent as MdGrowth } from '../images/ionicons/md-growth.svg'
import { ReactComponent as MdCloudUpload } from '../images/ionicons/md-cloud-upload.svg'
import { ReactComponent as MdDownload } from '../images/ionicons/md-download.svg'
import { ReactComponent as MdRecording } from '../images/ionicons/md-recording.svg'
import { ReactComponent as MdArrowDropleft } from '../images/ionicons/md-arrow-dropleft.svg'
import { ReactComponent as MdArrowDropright } from '../images/ionicons/md-arrow-dropright.svg'
import { ReactComponent as MdArrowDropup } from '../images/ionicons/md-arrow-dropup.svg'
import { ReactComponent as MdArrowDropdown } from '../images/ionicons/md-arrow-dropdown.svg'
import { ReactComponent as MdList } from '../images/ionicons/md-list.svg'
import { ReactComponent as MdMail } from '../images/ionicons/md-mail.svg'
import { ReactComponent as MdSettings } from '../images/ionicons/md-settings.svg'
import { ReactComponent as MdPower } from '../images/ionicons/md-power.svg'
import { ReactComponent as MdTrash } from '../images/ionicons/md-trash.svg'
import { ReactComponent as MdCard } from '../images/ionicons/md-card.svg'
import { ReactComponent as MdAlert } from '../images/ionicons/md-alert.svg'
import { ReactComponent as MdToday } from '../images/ionicons/md-today.svg'
import { ReactComponent as ChevronBack } from '../images/ionicons/chevron-back.svg'
import { ReactComponent as ChevronForward } from '../images/ionicons/chevron-forward.svg'
import { ReactComponent as MdStar } from '../images/ionicons/md-star.svg'
import { ReactComponent as MdStarOutline } from '../images/ionicons/md-star-outline.svg'
import { ReactComponent as basketBall } from '../images/ionicons/ios-basketball.svg'
import { ReactComponent as MdFilm } from '../images/ionicons/md-film.svg'
import { ReactComponent as IosShareAlt } from '../images/ionicons/ios-share-alt.svg'
import { ReactComponent as pitchtapeIcon } from '../images/icons/pitchtape.svg'
import { ReactComponent as refreshIcon } from '../images/icons/refresh.svg'
import { ReactComponent as check_simple } from '../images/icons/check_simple.svg'
import { ReactComponent as grouphashtag } from '../images/icons/grouphashtag.svg'
import { ReactComponent as groupbook } from '../images/icons/groupbook.svg'
import { ReactComponent as iosThumbsUp } from '../images/ionicons/ios-thumbs-up.svg'
import { ReactComponent as iosThumbsDown } from '../images/ionicons/ios-thumbs-down.svg'
import { ReactComponent as MdLike } from '../images/icons/like.svg'
import { ReactComponent as MdDislike } from '../images/icons/dislike.svg'
import { ReactComponent as bookicondark } from '../images/icons/bookicondark.svg'
import { ReactComponent as hashtagicondark } from '../images/icons/hashtagicondark.svg'
import { ReactComponent as search_normal } from '../images/icons/search_normal.svg'
import { ReactComponent as information_circle_outline } from "../images/ionicons/ios-information-circle-outline.svg";

const nameToIconMap = {
  add: MdAdd,
  remove: MdRemove,
  addCircleOutline: MdAddCircleOutline,
  removeCircleOutline: MdRemoveCircleOutline,
  arrowBack: MdArrowBack,
  arrowForward: MdArrowForward,
  business: MdBusiness,
  pin: MdPin,
  trendingUp: MdTrendingUp,
  briefcase: MdBriefcase,
  bookmark: MdBookmark,
  close: MdClose,
  checkmark: MdCheckmark,
  radioButtonOff: MdRadioButtonOff,
  radioButtonOn: MdRadioButtonOn,
  share: MdShare,
  play: MdPlay,
  playCircle: MdPlayCircle,
  pause: MdPause,
  volumeHigh: MdVolumeHigh,
  volumeOff: MdVolumeOff,
  expand: MdExpand,
  skipBackward: MdSkipBackward,
  skipForward: MdSkipForward,
  people: MdPeople,
  videocam: MdVideocam,
  document: MdDocument,
  create: MdCreate,
  bold: MdBold,
  italic: MdItalic,
  unorderedList: MdUnorderedList,
  orderedList: MdOrderedList,
  title: MdTitle,
  growth: MdGrowth,
  cloudUpload: MdCloudUpload,
  download: MdDownload,
  recording: MdRecording,
  arrowDropleft: MdArrowDropleft,
  arrowDropright: MdArrowDropright,
  arrowDropup: MdArrowDropup,
  arrowDropdown: MdArrowDropdown,
  list: MdList,
  mail: MdMail,
  settings: MdSettings,
  power: MdPower,
  trash: MdTrash,
  card: MdCard,
  alert: MdAlert,
  today: MdToday,
  chevronBack: ChevronBack,
  chevronForward: ChevronForward,
  star: MdStar,
  starOutline: MdStarOutline,
  basketBall: basketBall,
  film: MdFilm,
  iosShareAlt: IosShareAlt,
  pitchtapeIcon: pitchtapeIcon,
  refresh: refreshIcon,
  checkSimple: check_simple,
  grouphashtag: grouphashtag,
  groupbook: groupbook,
  thumbsUp: iosThumbsUp,
  thumbsDown: iosThumbsDown,
  like: MdLike,
  dislike: MdDislike,
  bookicondark: bookicondark,
  hashtagicondark: hashtagicondark,
  search_normal: search_normal,
  information_circle_outline,
}

const colorToFillMap = {
  blue: PRIMARY_COLOR,
  deepBlue: '#1e2f3e',
  green: '#66e8d2',
  black: '#131522',
  gray: '#979797',
  white: '#ffffff',
  red: '#ff4040',
  lightGray: '#ecedf0',
  yellow: '#ffba40',
  nba: '#C8102E',
  cioccacenter: '#9e1b32',
  ukri: '#BE2BBB',
  violet: '#8A1A9B',
  none: 'none'
}

const Ionicon = ({ variant = 'base', name, size = '16', color, stroke, strokeWidth, offset, style }) => {
  const Icon = nameToIconMap[name]

  return (
    <Icon
      className={classNames(css[variant], offset && css[`offset-${offset}`])}
      width={size}
      height={size}
      fill={colorToFillMap[color] || '#131522'}
      stroke={colorToFillMap[stroke] || '#131522'}
      strokeWidth={strokeWidth}
      style={style}
    />
  )
}

Ionicon.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  offset: PropTypes.string,
  style: PropTypes.object
}

export default Ionicon
