import css from './WelcomeModal.module.sass'

import React from 'react'

import Button from './Button'
import Modal from './Modal'
import Text from './Text'
import Link from "./Link"


import { CREATE_PATH } from '../constants/routes'

const WelcomeModal = () => {

  return (
    <Modal
      variant="welcome"
      title={<Text tag="h1" variant="h2" color="white">Welcome!</Text>}
      buttons={
        <Button to={CREATE_PATH} variant="primary">
          Create video
        </Button>
      }
    >
      <div className={css.content}>
        <Text tag="p" variant="larger" weight="400" offset="single-bottom">
          Thank you for creating your account on the Innovate UK Analysis for Innovators (A4I) Video Portal.
        </Text>

        <Text tag="p" variant="larger" weight="400" offset="single-bottom" className={css.p}>
          You can now record a 2 minute video to support your application.
          You will have access to video templates and recording tools.
        </Text>

        <Text tag="p" variant="larger" weight="400" offset="single-bottom">
          After completing your video, make sure to copy your video URL and paste it into your IFS application.
        </Text>
        <Text variant="larger" weight="400" offset="single-bottom">
          We are trialing this video platform as part of this competition.
          If you experience any technical difficulties whilst using the platform,
          you must contact Customer Support Service on
          &nbsp;
          <Link external href={'tel:+4403003214357'}>
            0300 321 4357
          </Link>
          &nbsp;
          <br />
          or
          <br />
          <Link href={"mailto:support@iuk.ukri.org"} external>
            support@iuk.ukri.org
          </Link>
          <br />
          before the submission deadline.
        </Text>
        <Text variant="larger" weight="400" >
          <br />
          <br />
          If you need assistance with using the video portal, please contact the KTN A4I support team at&nbsp;
          <br />
          <Link href={"mailto:a4i@ktnuk.org"} external>
            a4i@ktnuk.org
          </Link>
        </Text>
      </div>
    </Modal>
  )
}

export default WelcomeModal
