import PropTypes from "prop-types"
import React from "react"
import Ionicon from "../Ionicon";
import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link";
import { toWithParams } from "../../helpers/routes";
import { FEED_PATH } from "../../constants/routes";
import Tooltip from "../Tooltip";
import { RatingField } from "../Form"

export const formatRating = (rate) =>{
  return <RatingField
    name="rating"
    value={ rate }
  />

}

export const renderRated = (company) =>{
  if (company.givenRating !== -1) {
    return (<Tooltip hoverable content={'yes'} ><Ionicon name='checkmark' color='green' size='24' /></Tooltip>)
  } else {
    return (<Tooltip hoverable content={'no'}><Ionicon name='close' color='nba' size='24' /></Tooltip>)
  }
}

const FeedItem = ({ index, company }) => {
    const videoId = index + 1

    return (
        <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
            <td>
                {videoId}
            </td>
            <td>
                {company.name}
            </td>
            <td>
                {company.description}
            </td>
          <td>
            {company.highlights}
          </td>

            <td className={cssFeed.buttonAction}>
                <Link to={toWithParams(FEED_PATH, { videoId: videoId })}>
                    <Ionicon name='pitchtapeIcon' size='24' />
                </Link>
            </td>
            <td className={cssFeed.buttonAction}>
                {renderRated(company)}
            </td>
            <td>
                {formatRating(company.givenRating)}
            </td>

        </tr>
    )
}

FeedItem.propTypes = {
    index: PropTypes.number,
    company: PropTypes.object
}

export default FeedItem
