export const VIDEOS_AMOUNT = 3
export const VIDEO_DURATION = 60

export const VIDEO_CREATED_STATUS = 'created'
export const VIDEO_STARTED_STATUS = 'started'
export const VIDEO_FINISHED_STATUS = 'finished'

export const WHAT_WE_ARE_DOING = 'what_we_are_doing'
export const WHO_WE_ARE = 'who_we_are'
export const WHY_WE_ARE_DOING_IT = 'why_we_are_doing_it'

export const VIDEO_TITLES = {
  [WHAT_WE_ARE_DOING]: "Problem Description",
  [WHO_WE_ARE]: 'Problem Overview',
  [WHY_WE_ARE_DOING_IT]: "Other Information "
}

export const VIDEO_ORDER = [WHO_WE_ARE, WHAT_WE_ARE_DOING, WHY_WE_ARE_DOING_IT]

export const PLAYING_STATE = 'playing'
export const PAUSED_STATE = 'paused'
export const STOPPED_STATE = 'stopped'

export const TOPICS = [
  {
    id: WHO_WE_ARE,
    title: VIDEO_TITLES[WHO_WE_ARE],
    segments: [
      {
        id: 'intro',
        title: 'Problem Summary',
        question: 'Give us a summary of the problem you are facing and submitting to Analysis for Innovators.',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  },
  {
    id: WHAT_WE_ARE_DOING,
    title: VIDEO_TITLES[WHAT_WE_ARE_DOING],
    segments: [
      {
        id: 'problem',
        title: 'Problem Description',
        question: 'Demonstrate, show or describe the problem in as much detail as possible. Use the video to support the answer given in your application form rather than duplicating it. Show actual processes and what happens when the problem occurs if possible.',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  }, {
    id: WHY_WE_ARE_DOING_IT,
    title: VIDEO_TITLES[WHY_WE_ARE_DOING_IT],
    segments: [
      {
        id: 'ideation',
        title: 'Other Information ',
        question: 'Finally, show or describe anything else that might help scientists understand the problem you are facing.',
        teleprompt: 'Enter your teleprompter script here...'
      }
    ]
  }
]
