import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import SectionTitle from '../SectionTitle'
import Text from '../Text'
import Link from '../Link'

import { CREATE_VIDEOS_PATH } from '../../constants/routes'
import ExamplePitchtapeLink from "../ExamplePitchVideo"
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const FAQ_URL = 'https://docs.google.com/document/d/1GCWoiY0rXVvY-Zfy8dJdG7Lfl4Kvd_knKSJvS7otcZU/edit'

const VideoSection = ({ company }) => {
  const [supportedBrowser] = useState(Boolean(window.MediaRecorder && navigator.mediaDevices))

  const { concatenatedVideo } = company

  return (
    <section>
      <SectionTitle
        required
        title="3. Record Video"
        nextToTitle={
          (SHOW_EXAMPLES ? <Text>
            <ExamplePitchtapeLink
              linkText="See example"
              section="video"
            >
            </ExamplePitchtapeLink>
          </Text> : '')
        }
      />

      <div>
        <Button
          to={CREATE_VIDEOS_PATH}
          variant='primary'
          icon={
            <Ionicon
              name={!concatenatedVideo ? 'videocam' : 'create'}
              size='24'
              color={supportedBrowser ? 'white' : 'gray'}
            />
          }
          disabled={!supportedBrowser}
        >
          {!concatenatedVideo ? 'Record' : 'Edit'} video
        </Button>
      </div>

      {
        <Text tag='p' italic offset='half-top' weight='300'>
          Important: Please use Google Chrome to record.
          Click <Link href={FAQ_URL} variant="link" external>here</Link>
          &nbsp;for additional technical requirements.
        </Text>
      }
    </section>
  )
}

VideoSection.propTypes = {
  company: PropTypes.object
}

export default VideoSection
