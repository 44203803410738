import React from 'react'

import Tour from './Tour'

import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_ACTIVITY_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS,
  TOUR_INVESTOR_SAVE_CLASS,
  TOUR_INVESTOR_CONNECT_CLASS,
  TOUR_INVESTOR_RATE_CLASS,
  TOUR_INVESTOR_FEED_LIST_CLASS,
  TOUR_INVESTOR_SHARE_CLASS, TOUR_INVESTOR_PREV_NEXT_CLASS
} from '../constants/tour.js'
import {
  CONNECTIONS_MODULE_ENABLED,
  VIDEO_REVIEWER_PROFILE_ENABLED,
  VIDEO_CREATOR,
  VIDEO_CREATOR_PLURAL
}
  from "../constants/enterpriseConfig"

const CREATORS = VIDEO_CREATOR_PLURAL.toLocaleLowerCase()
const CREATOR = VIDEO_CREATOR.toLocaleLowerCase()


const InvestorTour = () =>
  <Tour
    steps={[
      {
        title: 'Feed',
        text: `Click on Feed to view one pitch at a time in an online feed.`,
        selector: '.' + TOUR_INVESTOR_FEED_CLASS
      },
      {
        title: 'List',
        text: `Click on List to view a summary all pitches on one page.`,
        selector: '.' + TOUR_INVESTOR_FEED_LIST_CLASS
      },
      {
        title: 'Rate',
        text: `Click on the rating icon to open the ratings tool.`,
        selector: '.' + TOUR_INVESTOR_RATE_CLASS
      },
      ...(CONNECTIONS_MODULE_ENABLED ? [{
        title: 'Activity Dashboard',
        text: `Click on the Activity Dashboard tab to view your activity on the platform. This is your private dashboard where you can view and send messages to the ${CREATORS} that you chose to Save or Connect.`,
        selector: '.' + TOUR_INVESTOR_ACTIVITY_CLASS
      }] : []),
      ...(VIDEO_REVIEWER_PROFILE_ENABLED ? [{
        title: 'Profile Settings',
        text: 'Click on the profile tab to access your account settings and to update your investor profile and investment criteria.',
        selector: '.' + TOUR_INVESTOR_PROFILE_CLASS
      }] : []),
      {
        title: 'Share',
        text: `Click on Share to copy the pitch URL to your clipboard.`,
        selector: '.' + TOUR_INVESTOR_SHARE_CLASS
      },
      {
        title: `Save`,
        text: `Click on Save to add the pitch to your list of saved pitches in your Saved List. The applicant will not be notified if you click to save.`,
        selector: '.' + TOUR_INVESTOR_SAVE_CLASS
      },
      ...(CONNECTIONS_MODULE_ENABLED ? [{
        title: `Connect with a ${VIDEO_CREATOR}`,
        text: `Click on the Connect button to add a ${CREATOR} to your list of connected ${CREATORS} in the activity tab. You will also be added to that ${CREATOR}'s list of connections in their private dashboard, and they will be able to reach out to you.`,
        selector: '.' + TOUR_INVESTOR_CONNECT_CLASS
      }] : []),

      {
        title: 'Previous/Next',
        text: `Click on the previous and next arrows to navigate back and forth through the pitches.`,
        selector: '.' + TOUR_INVESTOR_PREV_NEXT_CLASS
      }
    ]}
  />

export default InvestorTour
