import css from './ChatLayout.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import Link from './Link'
import Button from './Button'
import Loader from './Loader'
import { Consumer as DevicesConsumer } from './DevicesContext'

import { toWithParams } from '../helpers/routes'
import { STARTUP_PATH } from '../constants/routes'
import {PRODUCT_NAME} from "../constants/enterpriseConfig"

const ChatLayout = ({
  tabs, listLabel, list, connectionSelected, chat, companyId, noMessagesLabel, hasConnections, backUrl
}) => {
  return (
    <DevicesConsumer>
      {({ mobile }) =>
        <div className={css.container}>
          {tabs && !(mobile && connectionSelected) &&
            <div className={css.tabs}>
              {tabs}
            </div>
          }

          {mobile && hasConnections === void 0
            ? (
              <Loader variant='blank' />
            )
            : (
              <div className={css.listAndChat}>
                <div className={(!mobile || (hasConnections && !connectionSelected)) ? css.list : css.hidden}>
                  <div className={css.columnLabel}>
                    <Text variant='sectionTitleSmall' offset='half-bottom'>
                      {listLabel || '\u00A0'}
                    </Text>
                  </div>

                  <div className={css.listContent}>
                    {list}
                  </div>
                </div>

                {mobile && !hasConnections &&
                  <div className={css.columnContent}>
                    {chat}
                  </div>
                }

                <div className={!(mobile && !connectionSelected) ? css.chat : css.hidden}>
                  <div className={css.chatLabel}>
                    {!mobile
                      ? (
                        <Text variant='sectionTitleSmall' offset='half-bottom'>
                          {noMessagesLabel ? '\u00A0' : 'Messages'}
                        </Text>
                      )
                      : (
                        <Button variant='mobileNavbar' to={backUrl} icon='arrowLeftBlue'>
                          Connections
                        </Button>
                      )
                    }

                    {companyId && (
                      !mobile
                        ? <Link to={toWithParams(STARTUP_PATH, { companyId })}>View {PRODUCT_NAME}</Link>
                        : (
                          <Button
                            variant='mobileNavbar'
                            to={toWithParams(STARTUP_PATH, { companyId })}
                          >
                            View {PRODUCT_NAME}
                          </Button>
                        )
                    )}
                  </div>

                  <div className={css.chatContent}>
                    <div className={css.chatContentInner}>
                      {chat}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      }

    </DevicesConsumer>
  )
}

ChatLayout.propTypes = {
  tabs: PropTypes.node,
  listLabel: PropTypes.string,
  list: PropTypes.node,
  connectionSelected: PropTypes.bool,
  chat: PropTypes.node,
  companyId: PropTypes.string,
  noMessagesLabel: PropTypes.bool,
  hasConnections: PropTypes.bool,
  backUrl: PropTypes.string
}

export default ChatLayout
