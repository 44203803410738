import css from './NoCompanies.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'
import Button from '../Button'
import List from '../List'

import { FEED_PATH, PROFILE_PATH } from '../../constants/routes'
import {
  PORTAL_NAME,
  VIDEO_CREATOR,
  VIDEO_CREATOR_PLURAL,
  VIDEO_REVIEWER_PROFILE_ENABLED
} from "../../constants/enterpriseConfig"

const NoCompanies = ({ type }) => {
  return (
    <div className={css.container}>
      <div className={css.main}>
        {type === 'connected'
          ? <>
            <Icon name='chat' style={{ marginBottom: 10 }} />
            <Text tag='h1' variant='h3' offset='half-bottom'>Your messages will appear here</Text>
            <Text tag='p' offset='double-bottom'>Connect with a {VIDEO_CREATOR.toLowerCase()} and start a conversation.</Text>
          </>
          : <>
            <Icon name='files' style={{ marginBottom: 10 }} />
            <Text tag='h1' variant='h3' offset='half-bottom'>Review saved {VIDEO_CREATOR_PLURAL.toLowerCase()}</Text>
            <Text tag='p' offset='double-bottom'>
              {VIDEO_CREATOR_PLURAL} that you clicked to save are listed to the left.
              To view more {VIDEO_CREATOR_PLURAL.toLowerCase()}, click the button below.
            </Text>
          </>
        }
        <Button to={FEED_PATH} variant='primary'>View {VIDEO_CREATOR_PLURAL.toLowerCase()}</Button>
      </div>

      <List variant='centeredActions'>
        <List.Item style={{ maxWidth: 160 }}>
          <Button
            variant='iconOnTop'
            external
            href='http://pitchtape.com/blog'
            icon='blogBlue'
            iconMaxHeight={24}
          >
            Read Pitchtape&apos;s blog for tips on evaluating {VIDEO_CREATOR_PLURAL.toLowerCase()}
          </Button>
        </List.Item>

        <List.Item style={{ maxWidth: 115 }}>
          <Button variant='iconOnTop' to={FEED_PATH} icon='feedBlue' iconMaxHeight={24}>
            View {VIDEO_CREATOR_PLURAL.toLowerCase()} in your {PORTAL_NAME.toLowerCase()} feed
          </Button>
        </List.Item>
          {VIDEO_REVIEWER_PROFILE_ENABLED &&
          <List.Item style={{maxWidth: 110}}>
              <Button variant='iconOnTop' to={PROFILE_PATH} icon='pencilBlue' iconMaxHeight={24}>
                  Update your {VIDEO_CREATOR.toLowerCase()} profile
              </Button>
          </List.Item>
          }
      </List>
    </div>
  )
}

NoCompanies.propTypes = {
  type: PropTypes.string
}

export default NoCompanies
