import React from 'react'
import Text from "../Text"
import Link from "../Link"

import {
    RECORDING_TECHNICAL_URL
} from '../../constants/urls'

const CameraError = () => {
    return (
        <>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                There was an issue initializing your camera. To initiate the recorder,
                allow Pitchtape to access your camera and microphone.
            </Text>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                Ensure your camera and microphone are WebRTC compatible by
                visiting <Link external href='https://www.webrtc-experiment.com/DetectRTC/ '>https://www.webrtc-experiment.com/DetectRTC/. </Link>
            </Text>
            <Text tag='p' variant='standardLarger' offset='single-top'>
                For additional help, please visit our <Link external href={RECORDING_TECHNICAL_URL}>Technical Requirements.</Link>
            </Text>
        </>
    )
}

CameraError.propTypes = {}

export default CameraError