import css from './Star.module.sass'

import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import Ionicon from '../../Ionicon'
import DevicesContext from '../../DevicesContext'
import Text from "../../Text"

const Star = ({ variant, id, name, active, checked, value, color, stroke, onSelect, showLabels }) => {
  const { mobile } = useContext(DevicesContext)
  const textColor = active ? 'primary-active' : 'black'
  const textVariant = 'standard'

  const handleChange = useCallback(() => {
    if (onSelect) {
      onSelect({
        [name]: value
      })
    }
  }, [name, value, onSelect])

  return (
    <div className={`${css.container} ${showLabels ? (active ? css.showLabelsActive : css.showLabels) : ''}`}
      onClick={() => {
        if (showLabels) {
          handleChange()
        }
      }}>
      <input
        className={css.input}
        id={id}
        name={name}
        type='radio'
        checked={checked}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={id}>
        <Ionicon
          name={value === 1 ? 'like' : 'dislike'}
          size={mobile ? '30' : (variant === 'large' ? '36' : '24')}
          color={active ? (color || 'ukri') : 'none'}
          stroke={mobile ? ('') : stroke}
          style={(value === 0 ? { top: '1px' } : {})}
        />
        {showLabels ? (value === 1 ? <Text className={css.labelText} variant={textVariant} color={textColor}>Yes</Text>
          : <Text className={css.labelText} variant={textVariant} color={textColor}>No</Text>) : ''}
      </label>
    </div>
  )
}

Star.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.number,
  color: PropTypes.string,
  stroke: PropTypes.string,
  onSelect: PropTypes.func,
  showLabels: PropTypes.bool
}

export default Star
