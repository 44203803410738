import React, { useState } from 'react'
import css from './index.module.sass'
import { useQuery } from "react-apollo"
import { useLocalStorage } from 'react-use'

import { GET_FAVORITE_COMPANIES } from "../../constants/queries"
import Loader from "../Loader"
import CompanyItem from "./CompanyItem"
import Layout from "../Layout"
import Alert from "../Alert"
import { formatGraphQLError } from "../../helpers/errors"
import Button from "../Button"
import Search from '../../components/Search'
import Tooltip from "../Tooltip";
import Ionicon from "../Ionicon";

const SavedCompanies = () => {
  const [offset, setOffset] = useState(0)
  const [cursor, setCursor] = useState(null)
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const deleteConnectionCallBack = (connectionId) => {
    setData(data.filter(nodeItem => nodeItem.node.company.id !== connectionId))
  }

  const { data: savedData, loading, error, networkStatus, refetch: refetchList } = useQuery(GET_FAVORITE_COMPANIES, {
    onCompleted: (responseData) => {
      setData(data.concat(responseData?.getFavoriteCompanies.edges))
    },
    fetchPolicy: "network-only",
    variables: {
      first: 10,
      after: cursor,
      q: searchTerm
    }
  })

  const clearForQuery = () => {
    setData([])
    setOffset(0)
    refetchList()
  }

  const handleSearchTermChange = (value) => {
    setSearchTerm(value)
    clearForQuery()
  }
  const handleSearchTermClear = () => {
    removeSearchTerm()
    clearForQuery()
  }

  return <>
    {error &&
      <Layout variant='dashboard'>
        <Alert variant='error'>{formatGraphQLError(error)}</Alert>
      </Layout>
    }
    {
      <div className={css.containerSearch}>
        <Search
            term={searchTerm}
            onChange={handleSearchTermChange}
            onClear={handleSearchTermClear}
        />
        &nbsp;
        <Tooltip
            hoverable
            content="Note: The search tool does not search ratings, comments or notes."
        >
          <Ionicon
              name='information_circle_outline'
              size='24'
          />
        </Tooltip>

      </div>
    }
    <table className={css.dataTable}>
      <thead>
        <tr>
          <th>#</th>
          <th>Company Name</th>
          <th>Application Number</th>
          <th>Application Title</th>
          <th>Video</th>
          <th>Rated</th>
          <th>Rating</th>
          <th>Saved At</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((nodeItem, index) => (
            <CompanyItem key={index}
              index={index}
              connectionId={nodeItem.node.company.id}
              company={nodeItem.node.company}
              dateSaved={nodeItem.node.dateSaved}
              deleteCallBack={deleteConnectionCallBack} />
          ))
        }
      </tbody>
    </table>
    <div className={css.loadMore}>
      {savedData?.companies?.pageInfo?.hasNextPage &&
        <Button variant='outline' onClick={() => {
          setCursor(savedData?.companies?.pageInfo?.endCursor)
          setOffset(offset + 10)
        }
        }>
          Load More...
        </Button>
      }
    </div>
    {(loading || networkStatus === 4) &&
      <div className={css.loading}>
        <Loader variant='centered' />
      </div>
    }
  </>
}


SavedCompanies.propTypes = {
}

export default SavedCompanies
