import css from './Team.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Link from '../Link'
import SectionHeading from './SectionHeading'

import { removeProtocol } from '../../helpers/strings'

const Team = ({ company }) => {
  return (
    <section className={css.container}>
      <div className={css.content}>
        <SectionHeading icon='people'>
          Team
        </SectionHeading>

        <div className={css.members}>
          {company.members.map(member =>
            <article className={css.person} key={member.id}>
              <Text tag='h1' variant='h3' className={css.title}>
                {member.firstName} {member.lastName}, <Text variant={null} weight='300'>{member.title}</Text>
              </Text>

              <Text tag='p' className={css.link} truncated>
                <Link variant='inherit' external href={member.websiteUrl}>
                  {removeProtocol(member.websiteUrl)}
                </Link>
              </Text>

              {member.bio &&
                <Text tag='p'>
                  {member.bio}
                </Text>
              }
            </article>
          )}
        </div>
      </div>
    </section>
  )
}

Team.propTypes = {
  company: PropTypes.object
}

export default Team
