import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { RatingField } from '../Form'
import RatingModal from '../RatingModal'


const Rating = ({ company, onRate }) => {
  const [temporaryRating, setTemporaryRating] = useState(-1)
  const [modalOpen, setModalOpen] = useState(false)

  const { givenRating } = company

  const handleRatingUpdate = useCallback(({ ratingFeed }) => {
    if(temporaryRating === -1){
      setModalOpen(true)
      if(givenRating !== -1){
        setTemporaryRating(givenRating)
      }
    }
    setTemporaryRating(ratingFeed)

  }, [givenRating, temporaryRating])

  const handleRatingModalClose = useCallback(() => {
    setTemporaryRating(-1)
    setModalOpen(false)
  }, [])

  return (
    <>
      <RatingField
        name='ratingFeed'
        onUpdate={handleRatingUpdate}
        value={givenRating}
        variant='large'
        stroke='white'
      />

      {modalOpen &&
        <RatingModal
          company={company}
          defaultOverall={temporaryRating}
          onRate={onRate}
          onClose={handleRatingModalClose}
        />
      }
    </>
  )
}

Rating.propTypes = {
  company: PropTypes.object,
  onRate: PropTypes.func
}

export default Rating
