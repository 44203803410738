import css from './SectionTitle.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Asterisk from './Asterisk'
import Text from './Text'

const SectionTitle = ({ required, title, nextToTitle, description, highlight, className ='' }) => {
  return (
    <div className={css.container}>
      <div>
        <Text tag='h1' variant='blockTitle' inline highlight={highlight} className={className}>
          {title}
          {' '}
          {required && <Asterisk />}
        </Text>

        {' '}

        {nextToTitle &&
          <span className={css.nextToTitle}>
            {nextToTitle}
          </span>
        }
      </div>

      {description &&
        <Text tag='p' variant='light' italic offset='quarter-top'>{description}</Text>
      }
    </div>
  )
}

SectionTitle.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.node,
  nextToTitle: PropTypes.node,
  description: PropTypes.node,
  highlight: PropTypes.bool,
  className: PropTypes.string
}

export default SectionTitle
