import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import pick from 'lodash.pick'

import Link from '../Link'

import { GET_USER_COMPANY } from '../../constants/queries'
import { GTM_BUTTON_FOUNDER_CLICKED_CONTACT_EVENT } from '../../constants/gtm'
import { formatMailto } from '../../helpers/strings'
import { gtmTrack } from '../../helpers/tracking'

const EmailButton = ({ investorProfile }) =>
  <Query query={GET_USER_COMPANY}>
    {({ data, loading }) => {
      if (!data || loading) {
        return null
      }

      return (
        <Link
          variant='iconOnLeft'
          icon='envelope'
          href={formatMailto({
            email: investorProfile.createdBy.email,
            ...pick(investorProfile.contactEmailTemplate, [ 'subject', 'body', 'signature' ]),
            data: {
              'CompanyType': data.company,
              'InvestorProfileType': investorProfile
            }
          })}
          onClick={() => {
            gtmTrack(GTM_BUTTON_FOUNDER_CLICKED_CONTACT_EVENT)
          }}
        >
          Send email
        </Link>
      )
    }}
  </Query>

EmailButton.propTypes = {
  investorProfile: PropTypes.object
}

export default EmailButton
