import css from './Footer.module.sass'

import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import List from '../List'
import Link from '../Link'
import Ionicon from '../Ionicon'
import { PRODUCT_NAME } from "../../constants/enterpriseConfig";

const Footer = forwardRef(({ noDeck, noTeam, activeIndex, onSectionSelect }, ref) => {
  const [reducedGap, setReducedGap] = useState()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        if (!reducedGap) {
          setReducedGap(true)
        }
      } else if (reducedGap) {
        setReducedGap(false)
      }
    }

    if (reducedGap === void 0) {
      handleResize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [reducedGap])

  return (
    <div className={css.container} ref={ref}>
      <div className={css.floating}>
        <List variant='horizontal' gap={reducedGap ? '20' : void 0}>
          <List.Item>
            <Link
              variant='pitchtapeFooter'
              active={activeIndex === 0}
              onClick={onSectionSelect.bind(null, 0)}
            >
              <Ionicon name='videocam' size='24' offset='right-10' color={activeIndex === 0 ? 'white' : 'deepBlue'} />
              {PRODUCT_NAME}
            </Link>
          </List.Item>

          {!noDeck &&
            <List.Item>
              <Link
                variant='pitchtapeFooter'
                active={activeIndex === 1}
                onClick={onSectionSelect.bind(null, 1)}
              >
                <Ionicon name='document' size='24' offset='right-10' color={activeIndex === 1 ? 'white' : 'deepBlue'} />
                PITCH DECK
              </Link>
            </List.Item>
          }

          {!noTeam &&
            <List.Item>
              <Link
                variant='pitchtapeFooter'
                active={activeIndex === 3}
                onClick={onSectionSelect.bind(null, 3)}
              >
                <Ionicon name='people' size='24' offset='right-10' color={activeIndex === 3 ? 'white' : 'deepBlue'} />
                TEAM
              </Link>
            </List.Item>
          }
        </List>
      </div>
    </div>
  )
})

Footer.displayName = 'Footer'

Footer.propTypes = {
  noDeck: PropTypes.bool,
  noHighlights: PropTypes.bool,
  noTeam: PropTypes.bool,
  activeIndex: PropTypes.number,
  onSectionSelect: PropTypes.func
}

export default Footer
