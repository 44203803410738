import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { Redirect } from "react-router-dom"

import Alert from '../components/Alert'
import FounderAnalytics from '../components/FounderAnalytics'
import Layout from '../components/Layout'

import { formatGraphQLError } from '../helpers/errors'
import { ANALYTICS_DASHBOARD_ENABLED } from '../constants/enterpriseConfig'
import { HOME_PATH } from "../constants/routes"

const GET_INTERACTED_INVESTORS_COUNT = gql`
  query getInteractedInvestorsCount ($lastDays: Int, $id: String) {
    getInteractedInvestorsCount(lastDays: $lastDays, id: $id) {
      passed
      saved
      connected
    }
  }
`

const GET_COMPANY_ANALYTICS = gql`
  query getCompanyAnalytics ($id: String) {
    getCompanyAnalytics (id: $id) {
      totalViews
      totalViewsLastWeek
      avgViewTime
      avgViewTimeLastWeek
      avgRating {
        overall
        pitchVideo
        pitchDeck
        highlightsSection
        teamSection
        overallRatingCount
        pitchVideoRatingCount
        pitchDeckRatingCount
        highlightsSectionRatingCount
        teamSectionRatingCount
      }
      # videos {
      #   id
      #   duration
      #   subject
      #   totalViews
      #   avgViewTime
      # }
    }
  }
`

const GET_COMPANY_RATINGS = gql`
  query getCompanyRatings ($first: Int, $after: String) {
    getCompanyRatings(first: $first, after: $after) {
      edges {
        node {
          id
          review
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const FounderAnalyticsScreen = ({ match }) => {
  const { companyId } = match.params

  const {
    data: interactionsLastWeekData,
    loading: interactionsLastWeekLoading,
    error: interactionsLastWeekError
  } = useQuery(GET_INTERACTED_INVESTORS_COUNT, {
    fetchPolicy: 'network-only',
    variables: { lastDays: 7, id: companyId }
  })

  const interactionsLastWeek =
    interactionsLastWeekData && interactionsLastWeekData.getInteractedInvestorsCount

  const {
    data: interactionsAllTimeData,
    loading: interactionsAllTimeLoading,
    error: interactionsAllTimeError
  } = useQuery(GET_INTERACTED_INVESTORS_COUNT, {
    fetchPolicy: 'network-only',
    variables: { id: companyId }
  })

  const interactionsAllTime =
    interactionsAllTimeData && interactionsAllTimeData.getInteractedInvestorsCount

  const {
    data: companyAnalyticsData,
    loading: companyAnalyticsLoading,
    error: companyAnalyticsError
  } = useQuery(GET_COMPANY_ANALYTICS, {
    fetchPolicy: 'network-only',
    variables: { id: companyId }
  })

  const {
    totalViews,
    totalViewsLastWeek,
    avgViewTime,
    avgViewTimeLastWeek,
    avgRating
    // videos
  } = companyAnalyticsData ? companyAnalyticsData.getCompanyAnalytics : {}

  const {
    data: companyRatingsData,
    loading: companyRatingsLoading,
    error: companyRatingsError,
    fetchMore: fetchMoreCompanyRatings
  } = useQuery(GET_COMPANY_RATINGS, {
    fetchPolicy: 'network-only',
    variables: { id: companyId, first: 10 }
  })

  const reviewEdges = companyRatingsData?.getCompanyRatings?.edges
  const reviews = reviewEdges && reviewEdges.map(({ node }) => node)
  const hasMoreReviews = companyRatingsData?.getCompanyRatings?.pageInfo.hasNextPage

  const handleLoadMoreReviews = useCallback(() => {
    fetchMoreCompanyRatings({
      variables: {
        id: companyId,
        first: 10,
        after: companyRatingsData.getCompanyRatings.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult: next }) => {
        return {
          getCompanyRatings: {
            ...prev.getCompanyRatings,
            edges: [
              ...prev.getCompanyRatings.edges,
              ...next.getCompanyRatings.edges
            ],
            pageInfo: next.getCompanyRatings.pageInfo
          }
        }
      }
    })
  }, [companyId, companyRatingsData, fetchMoreCompanyRatings])

  if (!ANALYTICS_DASHBOARD_ENABLED)
    return <Redirect to={HOME_PATH} />

  return (
    <Layout variant='dashboardWithBlocks'>
      {interactionsLastWeekError &&
        <Alert variant='error'>{formatGraphQLError(interactionsLastWeekError)}</Alert>
      }
      {interactionsAllTimeError &&
        <Alert variant='error'>{formatGraphQLError(interactionsAllTimeError)}</Alert>
      }
      {companyAnalyticsError &&
        <Alert variant='error'>{formatGraphQLError(companyAnalyticsError)}</Alert>
      }
      {companyRatingsError &&
        <Alert variant='error'>{formatGraphQLError(companyRatingsError)}</Alert>
      }

      <FounderAnalytics
        interactionsLastWeekLoading={interactionsLastWeekLoading}
        interactionsAllTimeLoading={interactionsAllTimeLoading}
        companyAnalyticsLoading={companyAnalyticsLoading}
        interactionsLastWeek={interactionsLastWeek}
        interactionsAllTime={interactionsAllTime}
        totalViews={totalViews}
        totalViewsLastWeek={totalViewsLastWeek}
        avgViewTime={avgViewTime}
        avgViewTimeLastWeek={avgViewTimeLastWeek}
        avgRating={avgRating}
        reviewsLoading={companyRatingsLoading}
        reviews={reviews}
        hasMoreReviews={hasMoreReviews}
        onLoadMoreReviews={handleLoadMoreReviews}
      // videos={videos}
      />
    </Layout>
  )
}

FounderAnalyticsScreen.propTypes = {
  match: PropTypes.object
}

export default FounderAnalyticsScreen
