import css from './KnowledgeCenterBlock.module.sass'
import React, { useContext } from 'react'

import DevicesContext from '../DevicesContext'
import Link from '../Link'
import List from '../List'
import Well from '../Well'

import {
  VIDEO_PITCH_TEMPLATE_URL,
  RECORDING_TIPS_URL,
  VIDEO_TUTORIAL_URL, RECORDING_TECHNICAL_URL
} from '../../constants/urls'
import { PRACTICE_PATH } from '../../constants/routes'
import { SHOW_EXAMPLES } from "../../constants/enterpriseConfig"

const KnowledgeCenterBlock = () => {
  const { mobileUa } = useContext(DevicesContext)
  return (
    <Well title='Knowledge Centre' highlight className={css.knowledge_center}>
      <List gap="15">
        {SHOW_EXAMPLES &&
          <List.Item>
            <Link variant="inherit" external href={VIDEO_PITCH_TEMPLATE_URL}>
              Video pitch script template doc
            </Link>
          </List.Item>
        }

        <List.Item>
          <Link variant="inherit" external href={VIDEO_TUTORIAL_URL}>
            Watch tutorial video
          </Link>
        </List.Item>
        <List.Item>
          <Link variant="inherit" external href={RECORDING_TECHNICAL_URL}>
            Read technical requirements
          </Link>
        </List.Item>
        <List.Item>
          <Link variant="inherit" external href={RECORDING_TIPS_URL}>
            Read tips for recording a video
          </Link>
        </List.Item>
        {
          !mobileUa &&
          <List.Item>
            <Link variant="inherit" to={PRACTICE_PATH}>Practice pitching on video</Link>
          </List.Item>
        }
      </List>
    </Well>
  )
}

export default KnowledgeCenterBlock
