import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import CountBadge from './CountBadge'

export const GET_CONNECTED_INVESTORS_TOTAL_UNSEEN_AND_UNREAD = gql`
  query getConnectedInvestors {
    counts: getConnectedInvestors @connection(key: "investors") {
      totalUnseen
      totalUnreadMessages
    }
  }
`

export const GET_CONNECTED_COMPANIES_TOTAL_UNREAD = gql`
  query getConnectedInvestors {
    counts: getConnectedCompanies @connection(key: "connectedCompanies") {
      totalUnreadMessages
    }
  }
`

const ConnectionsBadge = ({ type }) =>
  <Query
    query={type === 'founder'
      ? GET_CONNECTED_INVESTORS_TOTAL_UNSEEN_AND_UNREAD
      : GET_CONNECTED_COMPANIES_TOTAL_UNREAD
    }
  >
    {({ data }) => {
      if (!data || !data.counts || (!data.counts.totalUnseen && !data.counts.totalUnreadMessages)) {
        return null
      }

      const { totalUnseen = 0, totalUnreadMessages = 0 } = data.counts

      const total = totalUnseen + totalUnreadMessages

      if (!total) {
        return null
      }

      return <CountBadge value={total} />
    }}
  </Query>

ConnectionsBadge.propTypes = {
  type: PropTypes.string
}

export default ConnectionsBadge
