import css from './index.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Asterisk from '../../Asterisk'
import Text from '../../Text'
import Well from '../../Well'
import Item from './Item'

import {
  DETAILS_SECTION, TEMPLATES_SECTION, VIDEO_SECTION,
  SUBMIT_SECTION, SHARE_SECTION
} from '../../../constants/pitchform'
import { areCompanyDetailsComplete } from '../../../helpers/companies'
import { PRODUCT_NAME, SUBMIT_VIDEO_ENABLED } from '../../../constants/enterpriseConfig'

const ProgressBlock = ({ company, onSelect }) => {
  let ITEMS = [
    {
      section: DETAILS_SECTION,
      title: 'Basic Info',
      required: true,
      complete: areCompanyDetailsComplete(company)
    },
    {
      section: TEMPLATES_SECTION,
      title: 'Teleprompter Script',
      complete: Boolean(company.teleprompts?.length)
    },
    {
      section: VIDEO_SECTION,
      title: 'Record Video',
      required: true,
      complete: Boolean(company.concatenatedVideo)
    },
    {
      section: SHARE_SECTION,
      title: 'Copy & Paste Video URL',
      required: true,
      complete: Boolean(company.copyPasteUrl)
    }
  ]

  const submitSectionItem = {
    section: SUBMIT_SECTION,
    title: 'Submit Video',
    required: true,
    complete: Boolean(company.publishedAt)
  }

  ITEMS = SUBMIT_VIDEO_ENABLED ? [
    ...ITEMS, submitSectionItem
  ] : ITEMS

  return (
    <Well title={`Your ${PRODUCT_NAME} Progress`}>
      <div className={css.container}>
        <Text tag='p'>
          <Asterisk /> <Text variant='standard' italic>Required</Text>
        </Text>

        <ul className={css.list}>
          {ITEMS.map((item, index) =>
            <Item
              key={index}
              index={index}
              {...item}
              onSelect={onSelect}
            />
          )}
        </ul>
      </div>
    </Well>
  )
}

ProgressBlock.propTypes = {
  company: PropTypes.object,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func
}

export default ProgressBlock
