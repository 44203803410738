export const TOUR_INVESTOR_FEED_CLASS = 'tour_investor_feed'
export const TOUR_INVESTOR_FEED_LIST_CLASS = 'tour_investor_feed_list'
export const TOUR_INVESTOR_ACTIVITY_CLASS = 'tour_investor_activity'
export const TOUR_INVESTOR_PROFILE_CLASS = 'tour_investor_profile'
export const TOUR_INVESTOR_PASS_CLASS = 'tour_investor_pass'
export const TOUR_INVESTOR_SAVE_CLASS = 'tour_investor_save'
export const TOUR_INVESTOR_CONNECT_CLASS = 'tour_investor_connect'
export const TOUR_INVESTOR_RATE_CLASS = 'tour_investor_rate'
export const TOUR_INVESTOR_FILTER_CLASS = 'tour_investor_filter'
export const TOUR_INVESTOR_SHARE_CLASS = 'tour_investor_share'
export const TOUR_INVESTOR_PREV_NEXT_CLASS = 'tour_investor_prev_next'
