import css from "./FeedListScreen.module.sass"

import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-apollo"
import apolloCLient from '../apollo'
import { useLocalStorage } from 'react-use'

import { GET_FEED_LIST } from "../constants/queries"
import Layout from "../components/Layout"
import Loader from "../components/Loader"
import Alert from "../components/Alert"
import { formatGraphQLError } from "../helpers/errors"
import FeedItem from "../components/FeedList/FeedItem"
import Button from "../components/Button"
import Search from '../components/Search'
import Tooltip from "../components/Tooltip";
import { VIDEO_CREATOR_PLURAL } from "../constants/enterpriseConfig"
import Ionicon from "../components/Ionicon";

const FeedListScreen = () => {
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const [searchTerm, setSearchTerm, removeSearchTerm] = useLocalStorage('feed-search-term', '')
  const { loading, error, networkStatus, refetch: refetchList } = useQuery(GET_FEED_LIST, {
    variables: {
      first: 10,
      offset,
      q: searchTerm
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getFeed?.total)
        setTotal(data.getFeed.total + offset)
      if (data?.getFeed?.edges) {
        setCompanies(companies.concat(data.getFeed.edges.map(({ node }) => node)))
      }
      if (data?.getFeed?.pageInfo?.hasNextPage) {
        setOffset(offset + 10)
      }
    }
  })

  const clearForQuery = () => {
    setCompanies([])
    setTotal(0)
    setOffset(0)
    refetchList()
  }

  const handleSearchTermChange = (value) => {
    setSearchTerm(value)
    clearForQuery()
  }
  const handleSearchTermClear = () => {
    removeSearchTerm()
    clearForQuery()
  }

  const renderFilter = () => {
    return <div className={css.filter}>
      <div className={css.totals}>
        Total {VIDEO_CREATOR_PLURAL} {total}
        <Button variant={'primarySmall'} className={css.refreshTable} onClick={() => {
          setCompanies([])
          setOffset(0)
          setTotal(0)
          // This hack enables to clear cache results
          Object.keys(apolloCLient.cache.data.data).forEach(key => {
            key.match(/^FeedCompanyType/) && apolloCLient.cache.data.delete(key)
          })
          refetchList()
        }}>
          Refresh Table
        </Button>
      </div>

      <div>
        <Search
            term={searchTerm}
            onChange={handleSearchTermChange}
            onClear={handleSearchTermClear}
        />
        &nbsp;
        <Tooltip
            hoverable
            content="Note: The search tool does not search ratings, comments or notes."
        >
          <Ionicon
              name='information_circle_outline'
              size='24'
          />
        </Tooltip>
      </div>
    </div>
  }

  const renderContent = () => {
    if (error) {
      return (
        <Layout variant='dashboard'>
          <Alert variant='error'>{formatGraphQLError(error)}</Alert>
        </Layout>
      )
    }
    return (
      <Layout background='white' noFooter>
        {renderFilter()}

        <table className={css.dataTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Company Name</th>
              <th>Application Number</th>
              <th>Application Title</th>
              <th>Video</th>
              <th>Rated</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <FeedItem key={index} index={index} company={company} />
            ))}
          </tbody>
        </table>
        {(loading || networkStatus === 4) &&
          <div className={css.loading}>
            <Loader variant='centered' />
          </div>
        }

      </Layout>
    )
  }
  return (<>
    {renderContent()}
  </>)
}


FeedListScreen.propTypes = {
  location: PropTypes.object
}

export default FeedListScreen
