export const PRODUCT_NAME = 'Video'
export const PORTAL_NAME = 'Innovate UK Analysis for Innovators (A4I) Video Portal'
export const FEED_NAME = 'Pitch Videos'
export const PRODUCT_NAME_SHORT = 'Pitch'
export const URL = 'https://www.ukri.org/councils/innovate-uk'
export const LOGO = 'ukri/logo.svg'
export const CONTACT_EMAIL = 'hello@pitchtape.com'
export const VIDEO_CREATOR = 'Applicant'
export const VIDEO_CREATOR_PLURAL = 'Applicants'
export const VIDEO_REVIEWER = 'Reviewer'
export const VIDEO_REVIEWER_PLURAL = 'Reviewers'
export const CONNECTIONS_MODULE_ENABLED = false
export const SAVED_MODULE_ENABLED = true
export const ANALYTICS_DASHBOARD_ENABLED = false
export const VIDEO_REVIEWER_TOUR_ENABLED = true
export const SHOW_EXAMPLES = false
export const LANDING_PAGE_ENABLED = true
export const VIDEO_REVIEWER_PROFILE_ENABLED = false
export const SIGNUP_REVIEWER_ENABLED = false
export const SUBMIT_VIDEO_ENABLED = false
export const MAP_RATING_QUESTIONS = {
  overall: '1. The company addresses a problem and states a clear opportunity to resolve it.',
  pitchVideo: '2. The company has considered next-step activities or resources to deliver on the product/service.',
  pitchDeck: '3. There is a clear description of how the firm will earn revenue and from whom.',
  teamSection: '4. The company addressed their competitors and identified their company\'s advantage.',
  highlightsSection: '5. The team told a compelling story.'
}
export const REQUIRE_RECORD_ALL_TOPICS = true
