import css from './TemplateSegment.module.sass'

import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Link from '../Link'
import Text from '../Text'
import { TextArea } from '../Form'

import { UNLOAD_TEXT } from '../../constants/forms'
import { useSaveCompanyTelepromptMutation } from '../../helpers/hooks'

const TemplateSegment = ({ segment, defaultTeleprompt = '' }) => {
  const [teleprompt, setTeleprompt] = useState(defaultTeleprompt)
  const [savedVisible, setSavedVisible] = useState()

  const saveTimeoutRef = useRef()

  const [savePrompt, { error }] = useSaveCompanyTelepromptMutation({
    onCompleted: () => {
      if (!saveTimeoutRef.current) {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  })


  const handleBeforeUnload = useCallback((event) => {
    event.preventDefault()
    event.returnValue = UNLOAD_TEXT
  }, [])

  const handlePromptUpdate = useCallback((obj) => {
    setTeleprompt(obj.teleprompt)

    window.addEventListener('beforeunload', handleBeforeUnload)

    clearTimeout(saveTimeoutRef.current)
    saveTimeoutRef.current = setTimeout(() => {
      saveTimeoutRef.current = null

      savePrompt({
        variables: {
          input: {
            subject: segment.id,
            script: obj.teleprompt
          }
        }
      })

      setSavedVisible(true)
    }, 500)
  }, [savePrompt, segment, handleBeforeUnload])

  const handleSave = useCallback(() => {
    savePrompt({
      variables: {
        input: {
          subject: segment.id,
          script: teleprompt
        }
      }
    })
  }, [savePrompt, teleprompt, segment])

  const saved = defaultTeleprompt === teleprompt.trim()

  return (
    <div key={segment.id} className={css.container}>
      <Text variant='standardLarger'>
        <Text variant={null} weight='500'>{segment.title}</Text>
      </Text>
      <br />
      <Text variant={null}>{segment.question}</Text>


      <div className={css.formVisible}>
        <TextArea
          rows='5'
          value={teleprompt}
          placeholder={segment.teleprompt}
          name="teleprompt"
          id={`teleprompt-${segment.id}`}
          counter={({ getWords }) =>
            <>
              {!error && savedVisible &&
                <Text variant='small' className={css.status}>
                  {saved ? 'saved' : <>saving<AnimatedEllipsis /></>}
                </Text>
              }

              {error &&
                <Text variant='small' color='red' className={css.retry}>
                  Not saved. <Link onClick={handleSave}>Retry</Link>
                </Text>
              }

              <Text variant='small'>
                {getWords()}
              </Text>
            </>
          }
          onUpdate={handlePromptUpdate}
        />
      </div>
    </div>
  )
}

TemplateSegment.propTypes = {
  segment: PropTypes.object,
  defaultTeleprompt: PropTypes.string,
  defaultOpen: PropTypes.bool
}

export default TemplateSegment
