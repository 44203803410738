import PropTypes from "prop-types"
import React from "react"
import cssFeed from "../../screens/FeedListScreen.module.sass"
import Link from "../Link"
import { toWithParams } from "../../helpers/routes"
import { STARTUP_PATH } from "../../constants/routes"
import Ionicon from "../Ionicon"
import Moment from "react-moment"
import DeleteSavedCompany from "../ConnectionList/DeleteSavedCompany"

import { formatRating, renderRated } from "../FeedList/FeedItem"

const CompanyItem = ({ index, connectionId, company, dateSaved, deleteCallBack }) => {
  const itemId = index + 1

  return (
    <tr className={index % 2 ? cssFeed.odd : cssFeed.even}>
      <td>
        {itemId}
      </td>
      <td>
        {company.name}
      </td>
      <td>
        {company.description}
      </td>

      <td>{company.highlights}</td>

      <td className={cssFeed.buttonAction}>
        <Link to={toWithParams(STARTUP_PATH, { companyId: company.id })}>
          <Ionicon name='pitchtapeIcon' size='24' />
        </Link>
      </td>
      <td className={cssFeed.buttonAction}>
        {renderRated(company)}
      </td>
      <td>
        {formatRating(company.givenRating)}
      </td>
      <td>
        {/* Using international standard for datetime format */}
        <Moment format="YYYY-MM-DD HH:mm:ss ">
          {dateSaved}
        </Moment>

      </td>
      <td>
        <DeleteSavedCompany connectionId={connectionId} deleteCallBack={deleteCallBack} />
      </td>

    </tr>
  )
}

CompanyItem.propTypes = {
  index: PropTypes.number,
  company: PropTypes.object,
  connectionId: PropTypes.string,
  dateSaved: PropTypes.string,
  deleteCallBack: PropTypes.func
}

export default CompanyItem
