import css from './Header.module.sass'

import React, { forwardRef, useState, useLayoutEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import CompanyDetails from './CompanyDetails'
import CompanyUrlCopier from '../CompanyUrlCopier'
import Ionicon from '../Ionicon'
import Text from '../Text'

// import InvestorActions from './InvestorActions'
import FavoritesList from './FavoritesList'
import Rating from './Rating'

import DevicesContext from '../DevicesContext'
import CurrentUserContext from '../CurrentUserContext'

import { CREATE_PATH } from '../../constants/routes'
import { isActivePaidPlan } from '../../helpers/subscription'
import { useDownloadVideoUrl } from '../../helpers/hooks'
import { TOUR_INVESTOR_PREV_NEXT_CLASS, TOUR_INVESTOR_SHARE_CLASS, TOUR_INVESTOR_RATE_CLASS } from "../../constants/tour"

const Header = forwardRef((
  {
    variant = 'base', company, editable, shareable, submitButton, sticky, forInvestor, pageMaxWidth, hasPrevious,
    hasNext, onPrevious, onNext, onRate, navigationButtons = true
  },
  ref
) => {
  const [floatingWidth, setFloatingWidth] = useState()

  const { mobile } = useContext(DevicesContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { subscription } = currentUser


  const containerRef = useRef()

  useLayoutEffect(() => {
    if (!sticky) {
      setFloatingWidth(null)
      return
    }

    const handleResize = () => {
      setFloatingWidth(containerRef.current.clientWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [sticky, pageMaxWidth])

  const downloadVideoUrl = useDownloadVideoUrl(company)

  const downloadable =
    downloadVideoUrl && (
      currentUser.isSuperuser ||
      (currentUser.id === Number(company.createdBy.id) && isActivePaidPlan(subscription))
    )

  return (
    <div className={css[variant]} ref={containerRef}>
      <div
        className={sticky ? css.headerFloating : css.header}
        style={sticky ? { width: floatingWidth } : void 0}
        ref={ref}
      >
        <header className={css.headerInner}>
          {!mobile &&
            <div className={css.details}>
              <CompanyDetails company={company} />
            </div>
          }

          <div className={css.ratingAndButtons}>
            <div className={css.HeaderRating}>
              {forInvestor && !mobile &&
                <div className={`${css.rating} ${TOUR_INVESTOR_RATE_CLASS}`}>
                  <Text variant='large'>
                    {company.givenRating < 0 ? 'Submit Feedback (click icon):' : 'Your Feedback (click icon to edit):'}
                  </Text>

                  <Rating company={company} onRate={onRate} />
                </div>
              }
            </div>
            <div className={`${css.buttons} `} >
              {downloadable &&
                <Button
                  variant='feedAction'
                  icon={
                    <Ionicon name='download' color='white' size='24' />
                  }
                  href={downloadVideoUrl}
                  external
                >
                  Download
                </Button>
              }

              {editable &&
                <Button
                  variant='feedAction'
                  icon={
                    <Ionicon name='create' color='white' size='24' />
                  }
                  to={CREATE_PATH}
                >
                  Edit
                </Button>
              }

              {shareable &&
                <CompanyUrlCopier company={company}>
                  <Button
                    variant='feedActionPrimary'
                    icon={
                      <Ionicon name='share' color='white' size='24' style={{ left: -2 }} />
                    }
                  >
                    <span className={TOUR_INVESTOR_SHARE_CLASS}>
                      Share
                    </span>
                  </Button>
                </CompanyUrlCopier>
              }

              {submitButton &&
                submitButton
              }

              {forInvestor &&
                <FavoritesList company={company} />
              }
              {forInvestor && navigationButtons &&
                <>
                  <div className={css.prevNext}>
                    <Button
                      variant='feedActionTransparent'
                      icon={
                        <Ionicon name='chevronBack' stroke='white' strokeWidth='32' size='48' />
                      }
                      disabled={!hasPrevious}
                      onClick={onPrevious}
                    >
                      <span>
                        Previous
                      </span>
                    </Button>
                    <span className={TOUR_INVESTOR_PREV_NEXT_CLASS}>
                    </span>
                    <Button
                      variant='feedActionTransparent'
                      className={css.btnNext}
                      icon={
                        <Ionicon name='chevronForward' stroke='white' strokeWidth='32' size='48' />
                      }
                      disabled={!hasNext}
                      onClick={onNext}
                    >
                      Next
                    </Button>
                  </div>
                </>
              }
            </div>
          </div>

        </header>
      </div>
    </div>
  )
})

Header.displayName = 'Header'

Header.propTypes = {
  variant: PropTypes.string,
  company: PropTypes.object,
  editable: PropTypes.bool,
  shareable: PropTypes.bool,
  submitButton: PropTypes.node,
  sticky: PropTypes.bool,
  forInvestor: PropTypes.bool,
  pageMaxWidth: PropTypes.number,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onInvestorAction: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onRate: PropTypes.func,
  navigationButtons: PropTypes.bool
}

export default Header
