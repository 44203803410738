import css from './CountBadge.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

const CountBadge = ({ value }) =>
  <span className={css.container}>
    <Text variant='smallest'>{value}</Text>
  </span>

CountBadge.propTypes = {
  value: PropTypes.number
}

export default CountBadge
